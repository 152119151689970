import React, { useEffect, useState, useContext } from 'react';
// REACT NATIVE COMPONENTS
import { View, TouchableOpacity } from "react-native";
// OWN ROOT COMPONENTS
import StyledText from '../../../../../components/StyledText';
import BelongIcon from '../../../../../components/BelongIcon';
// WRAPPERS AND CONTEXT CREATORS
import { FeathersContext } from "../../../../../services/feathers/FeathersProvider";
// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
// OTHER UTILS
import { useIsBig } from '../../../../../components/BelongResponsive';
// STYLING
const styles = {
    container: {
        backgroundColor: "#ffffff",
        marginBottom: 15,
        shadowColor: "#000000",
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.08,
        shadowRadius: 4,
        paddingVertical: 25,
        paddingHorizontal: 20
    },
    containerBig: {
        borderRadius: 10,
        marginBottom: 25
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    title: {
        color: '#333333',
        fontSize: 17,
        lineHeight: 22,
        fontWeight: '500'
    },
    subtitle: {
        marginTop: 5,
        color: '#CACACA',
        fontSize: 13,
        lineHeight: 13,
        fontWeight: '500'
    },
    icon: {
        color: '#CACACA'
    },
    options: {
        marginTop: 15
    },
    option: {
        backgroundColor: '#F1F1F1',
        borderRadius: 10,
        padding: 10,
        marginTop: 5
    },
    optionText: {
        color: '#444444',
        fontSize: 15,
        lineHeight: 24
    },
};
const Component = props => {
    const feathersContext = useContext(FeathersContext);
    const [question, setQuestion] = useState(null);
    const [voted, setVoted] = useState(false);
    const isBig = useIsBig();
    const { item } = props;
    useEffect(() => {
        feathersContext.onlyFind("modules", {
            query: {
                appId: 'fulrl',
                'votingBlocks.questions._id': item.reference
            }
        }).then(r => {
            if (r.length > 0) {
                const matching = r[0].votingBlocks.flatMap(vb => vb.questions).filter(q => q._id.toString() == item.reference);
                if (matching.length > 0 && matching[0].type == 'options')
                    setQuestion({ ...matching[0], moduleId: r[0]._id });
            }
        });
    }, []);
    useEffect(() => {
        if (question && question._id) {
            feathersContext.onlyFind("votes", {
                query: {
                    userId: props.services.app.feathersUser._id,
                    questionId: question._id,
                    deleted: false
                }
            }).then(r => {
                setVoted(r.total > 0);
            });
        }
    }, [question]);
    const sendVote = (optionId) => {
        feathersContext.create("votes", {
            moduleId: question.moduleId,
            questionId: question._id,
            optionId
        }).then(r => {
            setVoted(true);
        });
    };
    return question && !voted ? (<View style={[styles.container, isBig ? styles.containerBig : {}]}>
      <View style={styles.header}>
        <View>
          <StyledText style={styles.title}>{question.title}</StyledText>
          <StyledText style={styles.subtitle} tkey="voting.optionsTitle">Elige una opción</StyledText>
        </View>
        <BelongIcon name={`md-chart-pie`} size={30} style={styles.icon}/>
      </View>
      {question.options && question.options.length > 0 ?
        <View style={styles.options}>
          {question.options.map(o => (<TouchableOpacity key={o._id} onPress={() => sendVote(o._id)} style={styles.option}>
              <StyledText style={styles.optionText}>{o.title}</StyledText>
            </TouchableOpacity>))}
        </View>
        : null}

    </View>) : null;
};
export default connect((state) => ({
    services: {
        app: state.services.app,
    },
}), null)(Component);
