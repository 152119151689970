import React, { useState, useEffect, useContext } from 'react';
// REACT NATIVE COMPONENTS
import { View, FlatList } from "react-native";
// OWN ROOT COMPONENTS
import CollapsibleWrapper from '../../../components/CollapsibleWrapper';
import CollapsibleHeader from '../../../components/CollapsibleHeader';
import StyledText from '../../../components/StyledText';
import ListItem from "./components/ListItem";
import BelongSpinner from '../../../components/BelongSpinner';
import BelongIcon from "../../../components/BelongIcon";
import BigHeader from "../../../components/BelongHeader/big";
// WRAPPERS AND CONTEXT CREATORS
import { FeathersContext } from "../../../services/feathers/FeathersProvider";
// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
// OTHER UTILS
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native-gesture-handler';
import MediaQuery, { useIsBig } from '../../../components/BelongResponsive';
// STYLING
const styles = {
    contentContainerBig: {
        marginHorizontal: 'auto',
        width: '100%',
        maxWidth: 675,
        backgroundColor: '#ffffff',
        borderRadius: 10,
        shadowColor: "#333",
        shadowOffset: {
            width: 0,
            height: 5,
        },
        shadowOpacity: 0.2,
        shadowRadius: 6.68,
        elevation: 11,
    },
    headerDesktop: {
        height: 100,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    desktopBackWrap: {
        backgroundColor: 'rgba(55,66,96,0.05)',
        width: 36,
        height: 36,
        borderRadius: 18,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    barButton: {
        height: 30,
        borderRadius: 15,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    barIcon: {
        fontSize: 24,
    },
    title: {
        fontSize: 30,
        fontWeight: "500",
        color: "#FFFFFF",
        marginTop: 5,
    },
};
const Component = props => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [fullModule, setFullModule] = useState(null);
    // FEATHERS CONTEXT LOAD
    const feathersContext = useContext(FeathersContext);
    const { t, i18n } = useTranslation();
    const isBig = useIsBig();
    const initialLoad = () => {
        setLoading(true);
        setFullModule(null);
        feathersContext
            .get("modules", props.route.params.module._id)
            .then((r) => {
            setFullModule(r);
            setLoading(false);
            setError(false);
            // setTimeout(() => {
            //   setLoading(false);
            //   setError(true);
            // }, 3000)
        })
            .catch((e) => {
            console.log(e);
            setLoading(false);
            setError(true);
        });
    };
    useEffect(() => {
        initialLoad();
        feathersContext.client.service('modules').on("updated", initialLoad);
        feathersContext.client.service('modules').on("patched", initialLoad);
        return () => {
            feathersContext.client.service('modules').off("updated", initialLoad);
            feathersContext.client.service('modules').off("patched", initialLoad);
        };
    }, []);
    return (fullModule ?
        <React.Fragment>
        <MediaQuery minWidth={800}>
          <BigHeader navigation={props.navigation}>
            {props.services.app.manifest.title}
          </BigHeader>
        </MediaQuery>
        <CollapsibleWrapper headerComponent={(disappearingOpacity, appearingOpacity) => (<React.Fragment>
              <MediaQuery minWidth={800}>
                <View style={styles.headerDesktop}>
                  <TouchableOpacity style={[styles.barButton, isBig ? styles.desktopBackWrap : {}]} onPress={() => {
            props.navigation.goBack();
        }}>
                    <StyledText>
                      <BelongIcon name="md-cheveron-left" style={{
            ...styles.barIcon,
            color: '#ffffff',
        }}/>
                    </StyledText>
                  </TouchableOpacity>
                  <StyledText variant="h1" style={styles.title}>
                    {props.route.params.module.title}
                  </StyledText>
                  <View>
                  </View>
                </View>
              </MediaQuery>
              <MediaQuery maxWidth={799}>
                <CollapsibleHeader negative central={<StyledText variant="h1" style={styles.title}>
                      {props.route.params.module.title}
                    </StyledText>} backAction={() => props.navigation.goBack()} 
        // mainAction={() => props.actions.notifications.readAll()}
        // mainActionTitle="Leer todas"
        disappearingOpacity={disappearingOpacity} appearingOpacity={appearingOpacity} themeColor={props.services.app.manifest.themeColor}/>
              </MediaQuery>
            </React.Fragment>)} backgroundColor="#EEEFF3" bodyBackgroundColor="#FFFFFF" headerComponentStyle={{
            backgroundColor: props.services.app.manifest.themeColor,
            paddingHorizontal: 15,
        }} bodyComponentStyle={{
            flex: 1,
        }} scrollViewOverride={(onScroll, scrollEventThrottle, contentContainerPadding) => (loading || error ?
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', paddingTop: contentContainerPadding }}>
                <BelongSpinner error={error}/>
              </View>
            :
                <View style={{
                    ...styles.list,
                    backgroundColor: '#ffffff',
                    flex: 1
                }}>
                <FlatList contentContainerStyle={{
                    marginTop: contentContainerPadding + (isBig ? 50 : 0),
                    ...(isBig ? styles.contentContainerBig : {}),
                }} onScroll={onScroll} scrollEventThrottle={scrollEventThrottle} keyExtractor={(item, index) => item._id} style={{ backgroundColor: '#EEEFF3' }} data={fullModule.votingBlocks.filter(vb => vb.active)} renderItem={({ key, item, index }) => (<ListItem key={key} item={item} onPress={() => {
                    item.voted ||
                        props.navigation.navigate("VotingBlock", {
                            moduleId: fullModule._id,
                            block: item,
                            initialLoad: initialLoad,
                        });
                }}/>)}/>
              </View>)}/>
      </React.Fragment>
        : null);
};
export default connect((state) => ({
    services: {
        app: state.services.app,
    },
}), null)(Component);
