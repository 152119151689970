import React, { useState, useEffect, useContext, useRef } from 'react';
// REACT NATIVE COMPONENTS
import { View, KeyboardAvoidingView, Platform, TextInput, TouchableOpacity, FlatList, Keyboard, ScrollView } from "react-native";
import { useSafeAreaInsets } from 'react-native-safe-area-context';
// OWN ROOT COMPONENTS
import SmallHeader from '../../../../components/SmallHeader';
import BelongIcon from '../../../../components/BelongIcon';
import BigHeader from "../../../../components/BelongHeader/big";
import TimelineItem from '../components/TimelineItem';
// WRAPPERS AND CONTEXT CREATORS
import { FeathersContext } from "../../../../services/feathers/FeathersProvider";
// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
import CommentItem from './components/CommentItem';
// OTHER UTILS
import { useTranslation } from 'react-i18next';
import MediaQuery, { useIsBig } from '../../../../components/BelongResponsive';
// STYLING
const styles = {
    container: {
        position: 'relative',
        flex: 1
    },
    containerBig: {
        backgroundColor: '#EEEFF3',
        paddingBottom: 60
    },
    contentContainerBig: {
        marginHorizontal: 'auto',
        width: '100%',
        maxWidth: 675,
    },
    commentContainerBig: {
        backgroundColor: "#ffffff",
        shadowColor: "#000000",
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.08,
        shadowRadius: 4,
        borderRadius: 20,
    },
    inputWrap: {
        width: '100%',
        backgroundColor: '#EEEFF3',
        padding: 10,
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 5
    },
    inputWrapBig: {
        marginHorizontal: 'auto',
        maxWidth: 675,
        padding: 0,
        marginVertical: 20
    },
    inputBubble: {
        backgroundColor: '#ffffff',
        paddingHorizontal: 16,
        paddingTop: 7,
        borderRadius: 25,
        flex: 1
    },
    input: {
        fontFamily: 'Matter',
        maxHeight: 100,
        paddingTop: 7,
        paddingBottom: 15,
        outline: 'none'
    },
    submit: {
        paddingHorizontal: 15,
        paddingVertical: 15,
    }
};
const Commenter = props => {
    const [comment, setComment] = useState("");
    const feathersContext = useContext(FeathersContext);
    const { t, i18n } = useTranslation();
    const isBig = useIsBig();
    const submitComment = () => {
        if (comment != "") {
            feathersContext.patch("posts", props.item._id, {
                $push: { comments: { userId: props.userId, content: comment } }
            }).then(r => {
                props.setFullItem(r);
                setComment("");
                Keyboard.dismiss();
            });
        }
    };
    return (<View style={{ ...styles.inputWrap, ...(isBig ? styles.inputWrapBig : {}) }}>
      <View style={styles.inputBubble}>
        <TextInput placeholder={t('timeline.commentTitle')} textAlignVertical='top' multiline numberOfLines={4} value={comment} onFocus={() => setTimeout(props.scrollToBottom, 100)} onChangeText={t => setComment(t)} style={styles.input}/>
      </View>
      <TouchableOpacity onPress={submitComment} style={styles.submit}>
        <BelongIcon name="md-arrow-right" size={20}/>
      </TouchableOpacity>
    </View>);
};
const Comments = props => {
    const [fullItem, setFullItem] = useState(null);
    const scrollView = useRef(null);
    const insets = useSafeAreaInsets();
    // FEATHERS CONTEXT LOAD
    const feathersContext = useContext(FeathersContext);
    const { t, i18n } = useTranslation();
    const isBig = useIsBig();
    const item = props.route.params.item;
    const loadItem = () => {
        feathersContext.get('posts', props.route.params.item._id).then(r => setFullItem(r));
    };
    useEffect(() => {
        loadItem();
    }, [props.route.params.item._id]);
    return (<View style={{ ...(isBig ? styles.containerBig : {}), flex: 1 }}>
      <MediaQuery minWidth={800}>
        <BigHeader navigation={props.navigation}>
          {props.services.app.manifest.title}
        </BigHeader>
      </MediaQuery>
      <SmallHeader title={t('timeline.commentTitle')} noRound backAction={() => props.navigation.goBack()}/>
      <KeyboardAvoidingView behavior={Platform.OS == "ios" ? "padding" : "height"} style={{ ...styles.container }}>
        {fullItem ?
        <ScrollView style={{ flex: 1 }}>
          {item ?
            <View style={{ ...(isBig ? styles.contentContainerBig : { backgroundColor: '#fff' }) }}>
                <TimelineItem shortPic getRef={(ref) => { }} item={item} isMine={false} viewable={true} muted={true} toggleMuted={() => { }} onLike={() => {
                if (item.userLikes) {
                    feathersContext.patchAndUpdate("posts", item._id, {
                        toggleLike: false,
                    });
                }
                else {
                    feathersContext.patchAndUpdate("posts", item._id, {
                        toggleLike: true,
                    });
                }
            }} themeColor={props.services.app.manifest.themeColor} navigation={props.navigation} hideButtons t={t} style={isBig ? { borderRadius: 20, marginBottom: 20 } : {
                borderTopRightRadius: 20,
                borderTopLeftRadius: 20,
            }} isHomeItem/>
                </View>
            : null}
            <FlatList ref={ref => { scrollView.current = ref; }} ListHeaderComponent={() => null} style={{ borderRadius: 20, flex: 1 }} contentContainerStyle={{ ...(isBig ? { ...styles.contentContainerBig, ...styles.commentContainerBig } : { backgroundColor: '#fff' }) }} 
        //columnWrapperStyle={{ ...(isBig ? styles.commentContainerBig : {}) }}
        data={fullItem.comments} renderItem={({ item }) => (<CommentItem env={props.services.app.env} themeColor={props.services.app.manifest.themeColor} comment={item}/>)} ItemSeparatorComponent={() => <View style={{ marginHorizontal: 15, height: 1, backgroundColor: '#e9e9e9' }}></View>}/>
        </ScrollView>
        : null}
        <Commenter setFullItem={setFullItem} item={item} userId={props.services.app.feathersUser._id} scrollToBottom={() => scrollView.current.scrollToEnd({ animated: true })}/>
      </KeyboardAvoidingView>
    </View>);
};
export default connect((state) => ({
    services: {
        app: state.services.app,
    },
}), null)(Comments);
