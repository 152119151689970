import React, { useContext } from "react";
// REACT NATIVE COMPONENTS
import { SafeAreaView } from "react-native-safe-area-context";
import { View, KeyboardAvoidingView, Platform, TouchableWithoutFeedback, Keyboard, ScrollView } from "react-native";
// OWN ROOT COMPONENTS
import StyledText from "../../components/StyledText";
import { Form, FormGroup, Input } from "../../components/Form/index.tsx";
import BelongButton from "../../components/BelongButton";
import { useAlert } from "../../components/BelongAlert";
// WRAPPERS AND CONTEXT CREATORS
import { FeathersContext } from "../../services/feathers/FeathersProvider";
// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
// OTHER UTILS
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
// STYLING
const styles = {
    container: {
        backgroundColor: "#8681FF",
        flex: 1,
    },
    scrollView: {
        paddingHorizontal: 15,
        flex: 1,
    },
    scrollContent: {
        flexGrow: 1,
        paddingVertical: 25,
    },
    inner: {
        flex: 1,
        alignItems: "stretch",
        justifyContent: "space-between",
    },
    headerArea: {
        marginBottom: 25,
        flexGrow: 1,
        flexShrink: 0,
        flexBasis: 'auto',
        justifyContent: 'center',
    },
    subtitle: {
        marginTop: 10,
    },
    footerArea: {
        flexGrow: 1,
        flexShrink: 0,
        flexBasis: 'auto',
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center"
    }
};
const SignUp = (props) => {
    // FORM CONTEXT LOAD
    const { control, handleSubmit, errors, reset, setValue, clearErrors, formState, getValues } = useForm();
    // FEATHERS CONTEXT LOAD
    const feathersContext = useContext(FeathersContext);
    const { t, i18n } = useTranslation();
    const Alert = useAlert();
    const onSubmit = (data) => {
        // if (data.email == "angel@padcelona.com" && data.password == "123456") {
        //   props.navigation.goBack();
        // } else {
        //   Alert.alert("data", JSON.stringify(data));
        // }
        const _data = {
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            password: data.password
        };
        if (props.services.app.manifest.accessOptions.inviteRequired) {
            _data.inviteCode = data.inviteCode;
        }
        feathersContext
            .create("users", _data)
            .then(() => {
            feathersContext.authenticate(props.services.app.slug, data.email, data.password)
                .then(() => {
                cleanupAndNavigate();
            }).catch((e) => {
                props.navigation.navigate("SignIn");
            });
        })
            .catch((e) => {
            console.error("SignUp error", e);
            if (e.errors && e.errors.inviteCode && e.errors.inviteCode == 'Already used') {
                Alert.alert(t('signUp.inviteUsedTitle'), t('signUp.inviteUsedBody'));
            }
            else if (e.errors && e.errors.inviteCode && e.errors.inviteCode == 'Not found') {
                Alert.alert(t('signUp.wrongInviteTitle'), t('signUp.wrongInviteBody'));
            }
            else {
                Alert.alert(t('signUp.wrongTitle'), t('signUp.wrongBody'));
            }
        });
    };
    const cleanupAndNavigate = () => {
        reset();
        props.navigation.navigate("StackNavigator");
    };
    const validation = {
        email: {
            required: { value: true, message: t('signUp.validation.email.required') },
            pattern: {
                value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: t('signUp.validation.email.format'),
            },
        },
        firstName: {
            required: { value: true, message: t('signUp.validation.firstName.required') },
        },
        lastName: {
            required: { value: true, message: t('signUp.validation.lastName.required') },
        },
        password: {
            required: { value: true, message: t('signUp.validation.password.required') },
        },
    };
    const inviteRequired = props.services.app.manifest.accessOptions.inviteRequired;
    if (inviteRequired) {
        validation.inviteCode = {
            required: { value: true, message: t('signUp.validation.inviteCode.required') },
        };
    }
    return (<SafeAreaView style={{
        ...styles.container,
        backgroundColor: props.services.app.manifest.themeColor,
        flex: 1
    }}>

      <KeyboardAvoidingView behavior={Platform.OS == "ios" ? "padding" : "height"} style={styles.inner}>
        <ScrollView style={styles.scrollView} contentContainerStyle={styles.scrollContent}>
          <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
            <React.Fragment>
              <View style={styles.headerArea}>
                <StyledText tkey="signUp.title" variant="h1" white centered>
                  Registro
                </StyledText>
                <StyledText variant="h2a" white centered style={styles.subtitle}>
                  {props.services.app.manifest.title}
                </StyledText>
              </View>
              <Form {...{ control, validation, errors }}>
                <FormGroup elementType="FormGroup">
                  {inviteRequired ?
        <Input name="inviteCode" label={t('signUp.inviteCode')} autoCapitalize="none"/>
        : <React.Fragment></React.Fragment>}
                  <Input name="email" label={t('signUp.email')} keyboardType="email-address" autoCompleteType="email" autoCapitalize="none"/>
                  <Input name="firstName" label={t('signUp.firstName')}/>
                  <Input name="lastName" label={t('signUp.lastName')}/>
                  <Input name="password" label={t('signUp.password')} secureTextEntry last/>
                </FormGroup>
              </Form>
              <View style={styles.footerArea}>
                <BelongButton color={props.services.app.manifest.themeColor} white disabled={false} onPress={handleSubmit(onSubmit)} tkey="signUp.button" style={{ marginBottom: 10 }}>
                  Registro
                </BelongButton>
                <BelongButton color={props.services.app.manifest.themeColor} white onPress={() => props.navigation.goBack()} tkey="signUp.back" style={{ marginBottom: 10 }}>
                  Volver
                </BelongButton>
              </View>
            </React.Fragment>
          </TouchableWithoutFeedback>
        </ScrollView>
      </KeyboardAvoidingView>
    </SafeAreaView>);
};
export default connect((state) => ({
    services: {
        app: state.services.app,
    },
}), null)(SignUp);
