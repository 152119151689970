import React, { useContext, useState, useEffect, useCallback } from "react";
// REACT NATIVE COMPONENTS
import { SectionList, View, TextInput, TouchableOpacity } from "react-native";
// OWN ROOT COMPONENTS
import CollapsibleWrapper from "../../components/CollapsibleWrapper";
import CollapsibleHeader from "../../components/CollapsibleHeader";
import BelongIcon from "../../components/BelongIcon";
import StyledText from "../../components/StyledText";
import ListItem from "./components/ListItem";
// WRAPPERS AND CONTEXT CREATORS
import { FeathersContext } from "../../services/feathers/FeathersProvider";
// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
import { getGrouped } from "../../data/users/selectors";
// OTHER UTILS
import { useTranslation } from 'react-i18next';
import MediaQuery, { useIsBig } from '../../components/BelongResponsive';
// STYLING
const styles = {
    containerBig: {
        backgroundColor: '#E9EAEE',
        flex: 1,
        paddingBottom: 40,
        marginTop: 50,
    },
    contentContainerBig: {
        marginHorizontal: 'auto',
        width: '100%',
        maxWidth: 675,
        backgroundColor: '#FFFFFF',
        shadowColor: "#333",
        shadowOffset: {
            width: 0,
            height: 5,
        },
        shadowOpacity: 0.2,
        shadowRadius: 6.68,
        elevation: 11,
        borderRadius: 10,
    },
    headerDesktop: {
        height: 100,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    desktopBackWrap: {
        backgroundColor: 'rgba(55,66,96,0.05)',
        width: 36,
        height: 36,
        borderRadius: 18,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    barButton: {
        height: 30,
        borderRadius: 15,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    barIcon: {
        fontSize: 24,
    },
    title: {
        fontSize: 30,
        fontWeight: "500",
        color: "#FFFFFF",
        marginTop: 5,
    },
    list: {
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        backgroundColor: "#ffffff",
        flex: 1,
    },
    searchContainer: {
        marginHorizontal: 15,
        paddingHorizontal: 10,
        paddingVertical: 10,
    },
    searchBox: {
        fontFamily: "Matter",
        fontSize: 16,
        paddingVertical: 5,
        color: "#444444",
        outline: 'none'
    },
    headerContainer: {
        marginHorizontal: 15,
        paddingHorizontal: 10,
        paddingVertical: 10,
    },
    headerTitle: {
        fontSize: 14,
        color: "#8D8E8F",
    },
};
const UserList = (props) => {
    const [query, setQuery] = useState("");
    const [loadingMore, setLoadingMore] = useState(false);
    // FEATHERS CONTEXT LOAD
    const feathersContext = useContext(FeathersContext);
    const { t, i18n } = useTranslation();
    const initialLoad = useCallback(() => {
        feathersContext
            .find("users", {
            query: {
                invisible: false,
                $sort: {
                    lastName: 1,
                },
            },
        })
            .catch((e) => {
            console.log("Error", e);
        });
    }, []);
    const onEndReached = useCallback((info) => {
        const items = props.data.users.items;
        if (!loadingMore && items.length < props.data.users.total) {
            //alert(`More ${items.length} ${props.data.users.total}`);
            console.log("End was reached", props.data.users);
            setLoadingMore(true);
            feathersContext
                .findAndAppend("users", {
                query: {
                    ...(query != ""
                        ? {
                            invisible: false,
                            $or: [
                                { firstName: { $like: query, $search: query } },
                                { lastName: { $like: query, $search: query } },
                                { subtitle: { $like: query, $search: query } },
                            ],
                        }
                        : { invisible: false }),
                    $skip: items.length,
                    $sort: {
                        lastName: 1,
                    },
                },
            })
                .then(r => {
                setLoadingMore(false);
            })
                .catch((e) => {
                setLoadingMore(false);
                console.log("Error", e);
            });
        }
    }, [props.data.users, loadingMore]);
    const handleSearch = useCallback((t) => {
        setQuery(t);
        feathersContext
            .find("users", {
            query: {
                ...(t != ""
                    ? {
                        invisible: false,
                        $or: [
                            { firstName: { $like: t, $search: t } },
                            { lastName: { $like: t, $search: t } },
                            { subtitle: { $like: t, $search: t } },
                        ],
                    }
                    : { invisible: false }),
                $sort: {
                    lastName: 1,
                },
            },
        })
            .catch((e) => {
            console.log("Error", e);
        });
    }, []);
    useEffect(() => {
        initialLoad();
    }, []);
    console.log("GROUPED FROM COMPONENT", props.data.users.groups);
    const isBig = useIsBig();
    return (<CollapsibleWrapper headerComponent={(disappearingOpacity, appearingOpacity) => (<React.Fragment>
          <MediaQuery minWidth={800}>
            <View style={styles.headerDesktop}>
              <TouchableOpacity style={[styles.barButton, isBig ? styles.desktopBackWrap : {}]} onPress={() => {
        props.navigation.goBack();
        if (props.route.params && props.route.params.onGoBack)
            props.route.params.onGoBack();
    }}>
                <StyledText>
                  <BelongIcon name="md-cheveron-left" style={{
        ...styles.barIcon,
        color: '#ffffff',
    }}/>
                </StyledText>
              </TouchableOpacity>
              <StyledText variant="h1" style={styles.title}>
                {props.route.params ?
        props.route.params.title || props.route.params.module?.title
        :
            t('userList.title')}
              </StyledText>
              <View>
              </View>
            </View>
          </MediaQuery>
          <MediaQuery maxWidth={799}>
            <CollapsibleHeader negative central={<StyledText variant="h1" style={styles.title}>
                  {props.route.params ?
        props.route.params.title || props.route.params.module?.title
        :
            t('userList.title')}
                </StyledText>} backAction={() => props.navigation.goBack()} 
    // mainAction={() => {}}
    // mainActionTitle="Buscar"
    disappearingOpacity={disappearingOpacity} appearingOpacity={appearingOpacity} themeColor={props.services.app.manifest.themeColor}/>
          </MediaQuery>
        </React.Fragment>)} backgroundColor={isBig ? "#E9EAEE" : "#EEEFF3"} bodyBackgroundColor="#FFFFFF" headerComponentStyle={{
        backgroundColor: props.services.app.manifest.themeColor,
        paddingHorizontal: 15,
    }} bodyComponentStyle={{
        flex: 1
    }} scrollViewOverride={(onScroll, scrollEventThrottle, contentContainerPadding) => (<View style={{
        ...styles.list,
    }}>
          <SectionList style={isBig ? styles.containerBig : {}} contentContainerStyle={{ marginTop: contentContainerPadding, ...(isBig ? styles.contentContainerBig : {}) }} onScroll={onScroll} scrollEventThrottle={scrollEventThrottle} sections={props.data.users.groups} ListHeaderComponent={<View style={styles.searchContainer}>
                <TextInput value={query} onChangeText={(t) => handleSearch(t)} style={styles.searchBox} placeholder={t('userList.search')}/>
              </View>} renderItem={({ key, item, index }) => (<ListItem key={key} item={item} themeColor={props.services.app.manifest.themeColor} onPress={() => {
        props.navigation.navigate("UserProfile", { user: item });
    }}/>)} stickySectionHeadersEnabled={false} renderSectionHeader={({ section: { title } }) => (<View style={styles.headerContainer}>
                <StyledText style={styles.headerTitle}>{title}</StyledText>
              </View>)} keyExtractor={(item, index) => item._id} onEndReached={onEndReached} onEndReachedThreshold={0.1}/>
        </View>)}/>);
};
export default connect((state) => ({
    data: {
        users: {
            ...state.data.users,
            groups: getGrouped(),
        },
    },
    services: {
        app: state.services.app,
    },
}), null)(UserList);
