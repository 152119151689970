import React, { useEffect, useRef, useContext, createContext } from 'react';
import { AppState } from "react-native";
import mixpanel from 'mixpanel-browser';
const AnalyticsContext = createContext(null);
export { AnalyticsContext };
export const useAnalytics = () => useContext(AnalyticsContext);
export default ({ slug, children }) => {
    const appState = useRef(AppState.currentState);
    const _handleAppStateChange = (nextAppState) => {
        if (appState.current.match(/inactive|background/) &&
            nextAppState === "active") {
            //console.log("App has come to the foreground!");
            mixpanel.track("connected", { appId: slug, appType: 'web' });
        }
        else if (appState.current === "active" &&
            nextAppState.match(/inactive|background/)) {
            //console.log("App has come to the foreground!");
            mixpanel.track("disconnected", { appId: slug, appType: 'web' });
        }
        appState.current = nextAppState;
    };
    useEffect(async () => {
        async function initAnalytics() {
            mixpanel.init("1c2ad0dca35bbcdf21f0740aabccf4e0", {
                api_host: "https://api-eu.mixpanel.com",
            });
            mixpanel.track("connected", { appId: slug, appType: 'web' });
            // Set up app state monitoring
            AppState.addEventListener("change", _handleAppStateChange);
        }
        initAnalytics();
        return () => {
            AppState.removeEventListener("change", _handleAppStateChange);
        };
    }, [slug]);
    const setMixpanelUser = (user) => {
        mixpanel.identify(user._id);
        mixpanel.people.set({
            "$email": user.email,
            "USER_ID": user._id,
            "firstName": user.firstName,
            "lastName": user.lastName,
            "subtitle": user.subtitle,
            "audiences": user.audiences,
        });
    };
    const trackMixpanelEvent = async (event, data = {}) => {
        mixpanel.track(event, { appId: slug, appType: 'web', ...data });
    };
    return (<AnalyticsContext.Provider value={{ setMixpanelUser, trackMixpanelEvent }}>
      {children}
    </AnalyticsContext.Provider>);
};
// export default class MixpanelManager {
//     static sharedInstance = MixpanelManager.sharedInstance || new MixpanelManager();
//     constructor() {
//         this.configMixpanel();
//     }
//     configMixpanel = async () => {
//         this.mixpanel = await Mixpanel.init("1c2ad0dca35bbcdf21f0740aabccf4e0", { "api_host": "https://api-eu.mixpanel.com" }, "");
//     }
// }
