import { useWindowDimensions } from 'react-native';
export const useIsBig = (props) => {
    let valid = true;
    const window = useWindowDimensions();
    return window.width >= 800;
};
const MediaQuery = props => {
    let valid = true;
    const window = useWindowDimensions();
    if (props.minWidth) {
        if (window.width < props.minWidth)
            valid = false;
    }
    if (props.maxWidth) {
        if (window.width > props.maxWidth)
            valid = false;
    }
    if (valid) {
        return props.children;
    }
    else {
        return null;
    }
};
export default MediaQuery;
