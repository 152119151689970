import React, { createContext, useEffect, useState, useRef } from "react";
import { Platform, AppState } from 'react-native';
import feathers from "@feathersjs/feathers";
import socketio from "@feathersjs/socketio-client";
import auth from "@feathersjs/authentication-client";
import io from "socket.io-client";
import { Bugfender } from '@bugfender/rn-bugfender';
import AsyncStorage from "@react-native-community/async-storage";
import PushNotification from "./PushNotifications";
import Splash from '../../scenes/Splash';
//import { WS_BASE } from "./config";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { update as updateApp } from "../app/actions";
import * as postsActions from "../../data/posts/actions";
import * as notificationsActions from "../../data/notifications/actions";
import * as usersActions from "../../data/users/actions";
import * as announcementsActions from "../../data/announcements/actions";
import apiConfig from '../../services/api/config';
import { useAnalytics } from '../../Analytics';
const SERVICES = {
    posts: {
        actions: postsActions,
    },
    notifications: {
        actions: notificationsActions,
    },
    users: {
        actions: usersActions,
    },
    announcements: {
        actions: announcementsActions,
    },
};
const FeathersContext = createContext(null);
export { FeathersContext };
const FeathersProvider = ({ env, slug, deviceLocale, children, ...props }) => {
    let socket;
    let client;
    const appState = useRef(AppState.currentState);
    const [feathersObject, setFeathersObject] = useState(null);
    const [feathersReady, setFeathersReady] = useState(false);
    const [loadTime, setLoadTime] = useState(parseInt(new Date().getTime() / 1000));
    const lastActive = useRef(0);
    const dispatch = useDispatch();
    const { setMixpanelUser, trackMixpanelEvent } = useAnalytics();
    const processParams = (params) => {
        if (slug) {
            return {
                ...params,
                query: { ...(params.query ? params.query : {}), appId: slug },
            };
        }
        else {
            return params;
        }
    };
    const processData = (data) => {
        if (slug) {
            return { ...data, appId: slug };
        }
        else {
            return data;
        }
    };
    useEffect(() => {
        setFeathersReady(false);
        dispatch(updateApp({ feathersConnected: false, feathersUser: {} }));
        socket = io(apiConfig.url[env], {
            transports: ["websocket"],
            forceNew: true,
            query: {
                platform: 'mobile',
                slug
            }
        });
        client = feathers();
        client.configure(socketio(socket));
        client.configure(auth({
            storage: AsyncStorage,
            path: "/authentication/users",
            storageKey: `ubelong_${slug}_jwt`,
        }));
        const _find = (serviceName, params = {}) => client.service(serviceName).find(processParams(params));
        const get = (serviceName, id, params = {}) => client.service(serviceName).get(id, params);
        const create = (serviceName, data, params = {}) => client.service(serviceName).create(processData(data), params);
        const update = (serviceName, id, data, params = {}) => client.service(serviceName).update(id, data, params);
        const patch = (serviceName, id, data, params = {}) => client.service(serviceName).patch(id, data, params);
        const remove = (serviceName, id, params = {}) => client.service(serviceName).remove(id, params);
        const find = (serviceName, params = {}) => client
            .service(serviceName)
            .find(processParams(params))
            .then((r) => {
            dispatch(SERVICES[serviceName].actions.update(r.data, r.total));
        });
        const findAndAppend = (serviceName, params = {}) => client
            .service(serviceName)
            .find(processParams(params))
            .then((r) => {
            dispatch(SERVICES[serviceName].actions.append(r.data, r.total));
            return r;
        });
        const patchAndUpdate = (serviceName, id, data, params = {}) => client
            .service(serviceName)
            .patch(id, data, params)
            .then((r) => {
            dispatch(SERVICES[serviceName].actions.updateOne(r));
        });
        const removeAndUpdate = (serviceName, item, params = {}) => client
            .service(serviceName)
            .remove(item._id, params)
            .then((r) => {
            dispatch(SERVICES[serviceName].actions.destroy(item));
        }).catch(e => {
            throw (e);
        });
        const authenticate = (appId, email, password) => {
            return client
                .authenticate({
                strategy: "local",
                appId: appId,
                email: email,
                password: password,
            })
                .then((r) => {
                trackMixpanelEvent("loggedIn");
                setMixpanelUser(r.user);
                Bugfender.setDeviceKey('device.key.userId', r.user._id);
                dispatch(updateApp({ feathersAuthenticated: true, feathersUser: r.user }));
                // I reaload base data in case it's user dependant
                setTimeout(() => {
                    baseDataLoad();
                }, 4000);
            })
                .catch((e) => {
                dispatch(updateApp({ feathersAuthenticated: false, feathersUser: {} }));
                throw e;
            });
        };
        const logOut = () => {
            client.logout().then(r => {
                trackMixpanelEvent("loggedOut");
                if (Platform.OS == 'web')
                    window.location.reload();
            });
        };
        const updateUser = (userId, data) => patch("users", userId, data).then((r) => {
            dispatch(updateApp({ feathersUser: r }));
        });
        const baseDataLoad = () => {
            // I load notifications
            find('notifications', { query: { sent: true, $sort: { sentAt: -1 } } });
            // Refresh push token
            // if (props.services.app.pushToken) {
            //   notifyPushToken(props.services.app.pushToken);
            // }
        };
        const initialSeq = async () => {
            const result = await client
                .service("apps")
                .find({ query: { slug } });
            if (result.data.length > 0) {
                //dispatch(updateApp({ manifest: result.data[0] }));
                try {
                    // First try to log in with an existing JWT
                    const reauth = await client.authenticate();
                    //dispatch(updateApp({ feathersAuthenticated: true }));
                    const r = await client.get("authentication");
                    //dispatch(updateApp({ feathersUser: r.user }));
                    Bugfender.setDeviceKey('device.key.userId', r.user._id);
                    const loadDiff = parseInt(new Date().getTime() / 1000) - loadTime;
                    if (loadDiff < 2) {
                        setTimeout(() => {
                            setFeathersReady(true);
                        }, 2000 - loadDiff * 1000);
                    }
                    else
                        setFeathersReady(true);
                    dispatch(updateApp({ feathersAuthenticated: true, feathersUser: r.user, manifest: result.data[0] }));
                }
                catch (error) {
                    //dispatch(updateApp({ feathersAuthenticated: false, feathersUser: {} }));
                    const loadDiff = parseInt(new Date().getTime() / 1000) - loadTime;
                    if (loadDiff < 2) {
                        setTimeout(() => {
                            setFeathersReady(true);
                        }, 2000 - loadDiff * 1000);
                    }
                    else
                        setFeathersReady(true);
                    dispatch(updateApp({ feathersAuthenticated: false, feathersUser: {}, manifest: result.data[0] }));
                }
                setTimeout(() => {
                    baseDataLoad();
                }, 4000);
            }
        };
        const _handleAppStateChange = (nextAppState) => {
            if (nextAppState === "active") {
                if (new Date().getTime() - lastActive.current > 60000) {
                    initialSeq();
                }
                PushNotification.getApplicationIconBadgeNumber(function (number) {
                    if (number > 0) {
                        PushNotification.setApplicationIconBadgeNumber(0);
                    }
                });
            }
            else {
                lastActive.current = new Date().getTime();
            }
            appState.current = nextAppState;
            console.log("AppState", appState.current);
        };
        AppState.addEventListener("change", _handleAppStateChange);
        socket.on("connect", (connection) => {
            console.log("SOCKET CONNECYED");
            dispatch(updateApp({ feathersConnected: true }));
            // Same thing but sequential
            initialSeq();
        });
        socket.on("disconnect", (connection) => {
            console.log("SOCKET DISCONNECYED");
            dispatch(updateApp({ feathersConnected: false }));
            setFeathersReady(false);
        });
        setFeathersObject({
            socket: socket,
            client: client,
            authenticate,
            logOut,
            updateUser,
            onlyFind: _find,
            find,
            findAndAppend,
            patchAndUpdate,
            removeAndUpdate,
            get,
            create,
            update,
            patch,
            remove
        });
        return () => {
            AppState.removeEventListener("change", _handleAppStateChange);
        };
    }, [env, slug]);
    // useEffect(() => {
    //   // if (props.services.app.pushToken) {
    //   //   notifyPushToken(props.services.app.pushToken);
    //   // }
    //   if (props.services.app.pushToken && client && feathersReady) {
    //     alert(`notify ${JSON.stringify(props.services.app.feathersUser)}`)
    //     client.service('push-tokens').create({
    //       appId: slug,
    //       //userId, // I dont pass userId so that if it does an upsert it doesn't set the user id to null
    //       deviceId: getUniqueId(),
    //       token: props.services.app.pushToken,
    //       locale: deviceLocale,
    //       os: Platform.OS,
    //     });
    //   } else {
    //     alert(props.services.app.pushToken)
    //   }
    // }, [env, slug, props.services.app.pushToken, props.services.app.feathersUser, feathersReady]);
    return (<FeathersContext.Provider value={feathersObject}>
      {feathersReady ? children : <Splash feathersReady={feathersReady}/>}
    </FeathersContext.Provider>);
};
export default connect((state) => ({
    services: {
        app: state.services.app,
    },
}), null)(FeathersProvider);
