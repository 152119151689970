import React, { useContext, useState, useEffect } from "react";
// REACT NATIVE COMPONENTS
import { View, TouchableOpacity } from "react-native";
// OWN ROOT COMPONENTS
import CollapsibleWrapper from "../../../components/CollapsibleWrapper";
import CollapsibleHeader from "../../../components/CollapsibleHeader";
import StyledText from "../../../components/StyledText";
import BelongIcon from "../../../components/BelongIcon";
import TabBar from './components/TabBar';
import GameList from './components/GameList';
import RankingList from './components/RankingList';
import DocumentList from './components/DocumentList';
import BelongSpinner from "../../../components/BelongSpinner";
import BigHeader from "../../../components/BelongHeader/big";
// WRAPPERS AND CONTEXT CREATORS
import { FeathersContext } from "../../../services/feathers/FeathersProvider";
// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
// OTHER UTILS
import { useTranslation } from 'react-i18next';
import MediaQuery, { useIsBig } from '../../../components/BelongResponsive';
// STYLING
const styles = {
    headerDesktop: {
        height: 100,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    desktopBackOuter: {
        flex: 1
    },
    desktopBackWrap: {
        backgroundColor: 'rgba(55,66,96,0.05)',
        width: 36,
        height: 36,
        borderRadius: 18,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    barButton: {
        height: 30,
        borderRadius: 15,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    barIcon: {
        fontSize: 24,
    },
    contentContainerBig: {
        marginHorizontal: 'auto',
        width: '100%',
        maxWidth: 675,
        alignSelf: 'center'
    },
    title: {
        fontSize: 30,
        fontWeight: "500",
        color: "#FFFFFF",
        marginTop: 5,
    },
    list: {
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        backgroundColor: "#ffffff",
        flex: 1,
    },
};
const UserList = (props) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [fullModule, setFullModule] = useState(null);
    const [index, setIndex] = useState(0);
    // FEATHERS CONTEXT LOAD
    const feathersContext = useContext(FeathersContext);
    const { t, i18n } = useTranslation();
    const isBig = useIsBig();
    const initialLoad = () => {
        setLoading(true);
        setFullModule(null);
        feathersContext
            .get("modules", props.route.params.module._id)
            .then((r) => {
            setFullModule(r);
            setLoading(false);
            setError(false);
            // setTimeout(() => {
            //   setLoading(false);
            //   setError(true);
            // }, 3000)
        })
            .catch((e) => {
            console.log(e);
            setLoading(false);
            setError(true);
        });
    };
    useEffect(() => {
        initialLoad();
    }, []);
    return (<React.Fragment>
      <MediaQuery minWidth={800}>
        <BigHeader navigation={props.navigation}>
          {props.services.app.manifest.title}
        </BigHeader>
      </MediaQuery>
      <CollapsibleWrapper headerComponent={(disappearingOpacity, appearingOpacity) => (<React.Fragment>
            <MediaQuery minWidth={800}>
              <View style={styles.headerDesktop}>
                <View style={styles.desktopBackOuter}>
                  <TouchableOpacity style={[styles.barButton, isBig ? styles.desktopBackWrap : {}]} onPress={() => {
        props.navigation.goBack();
        if (props.route.params.onGoBack)
            props.route.params.onGoBack();
    }}>
                    <StyledText>
                      <BelongIcon name="md-cheveron-left" style={{
        ...styles.barIcon,
        color: '#ffffff',
    }}/>
                    </StyledText>
                  </TouchableOpacity>
                </View>
                <StyledText variant="h1" style={styles.title}>
                  {props.route.params.module.title}
                </StyledText>
                <View style={{ flex: 1, justifyContent: 'flex-end', alignItems: 'center', flexDirection: 'row' }}>
                  {fullModule ?
        <TabBar themeColor={props.services.app.manifest.themeColor} initialIndex={props.route.params.tabIndex || 0} tabs={[
            { title: t('gamification.games') },
            { title: t('gamification.documents') },
            { title: t('gamification.ranking') },
        ]} onIndexChange={setIndex}/>
        : null}
                </View>
              </View>
            </MediaQuery>
            <MediaQuery maxWidth={799}>
              <CollapsibleHeader central={<StyledText variant="h1" style={styles.title}>
                    {props.route.params.module.title}
                  </StyledText>} backAction={() => props.navigation.goBack()} 
    // mainAction={() => {}}
    // mainActionTitle="Buscar"
    disappearingOpacity={disappearingOpacity} appearingOpacity={appearingOpacity} themeColor={props.services.app.manifest.themeColor} negative bottomTitleOverride={fullModule ?
        <TabBar themeColor={props.services.app.manifest.themeColor} initialIndex={props.route.params.tabIndex || 0} tabs={[
            { title: t('gamification.games') },
            { title: t('gamification.documents') },
            { title: t('gamification.ranking') },
        ]} onIndexChange={setIndex}/>
        : null}/>
            </MediaQuery>
          </React.Fragment>)} backgroundColor="#EEEFF3" bodyBackgroundColor="#FFFFFF" headerComponentStyle={{
        backgroundColor: props.services.app.manifest.themeColor,
        paddingHorizontal: 15,
    }} bodyComponentStyle={{
        flex: 1,
    }} scrollViewOverride={(onScroll, scrollEventThrottle, contentContainerPadding) => (loading || error ?
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', paddingTop: contentContainerPadding }}>
              <BelongSpinner error={error}/>
            </View>
        :
            (fullModule ?
                <View style={{
                    ...styles.list,
                }}>
                  {index == 0 &&
                    <GameList games={fullModule.games.filter(g => g.active)} contentContainerStyle={{ paddingTop: contentContainerPadding, ...(isBig ? styles.contentContainerBig : {}) }} onScroll={onScroll} scrollEventThrottle={scrollEventThrottle} onItemPress={(item) => {
                        props.navigation.navigate("GamificationBlock", {
                            module: props.route.params.module,
                            game: item.item,
                            initialLoad,
                        });
                    }} onCompletedPress={(item) => {
                        props.navigation.navigate("GamificationCompletedBlock", {
                            module: props.route.params.module,
                            game: item.item,
                            initialLoad,
                        });
                    }}/>}
                  {index == 1 &&
                    <DocumentList contentContainerStyle={{ paddingTop: contentContainerPadding, ...(isBig ? styles.contentContainerBig : {}) }} onScroll={onScroll} scrollEventThrottle={scrollEventThrottle} module={fullModule} themeColor={props.services.app.manifest.themeColor}/>}
                  {index == 2 &&
                    <RankingList contentContainerStyle={{ paddingTop: contentContainerPadding, ...(isBig ? styles.contentContainerBig : {}) }} onScroll={onScroll} scrollEventThrottle={scrollEventThrottle}/>}

                </View>
                : null))}/>
    </React.Fragment>);
};
export default connect((state) => ({
    data: {},
    services: {
        app: state.services.app,
    },
}), null)(UserList);
