import React, { useRef, useEffect, useContext, useState, useCallback, } from "react";
import { View, FlatList, TouchableOpacity, Animated, TextInput } from "react-native";
import StyledText from "../../../components/StyledText";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as postsActionCreators from "../../../data/posts/actions";
import { FeathersContext } from "../../../services/feathers/FeathersProvider";
import CollapsibleWrapper from "../../../components/CollapsibleWrapper";
import BigHeader from '../../../components/BelongHeader/big';
import BelongIcon from "../../../components/BelongIcon";
import FloatingAction from "../../../components/FloatingAction";
import TimelineItem from "./components/TimelineItem";
import SurveyItem from "./components/SurveyItem";
import BelongSpinner from "../../../components/BelongSpinner";
import hextorgba from "../../../utils/hextorgba";
import { useTranslation } from 'react-i18next';
import { useAnalytics } from '../../../Analytics';
import mediaDownloadHandler from "./components/mediaDownloadHandler";
import { useAlert } from "../../../components/BelongAlert";
import MediaQuery, { useIsBig } from '../../../components/BelongResponsive';
const styles = {
    headerContainer: {
        flex: 1,
        flexDirection: "column",
    },
    headerUpper: {
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        overflow: "visible",
    },
    title: {
        fontSize: 30,
        fontWeight: "500",
        color: "#FFFFFF",
        marginTop: 5,
    },
    headerLower: {
        height: 56,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    headerDesktop: {
        height: 100,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    desktopSearchWrapOuter: {
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexDirection: 'row'
    },
    desktopSearchWrap: {
        backgroundColor: 'rgba(55,66,96,0.1)',
        width: 210,
        height: 36,
        borderRadius: 18,
        flexDirection: 'row',
        alignItems: 'stretch',
        paddingHorizontal: 20
    },
    desktopBackOuter: {
        flex: 1
    },
    desktopBackWrap: {
        backgroundColor: 'rgba(55,66,96,0.05)',
        width: 36,
        height: 36,
        borderRadius: 18,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    barButton: {
        height: 30,
        borderRadius: 15,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    barIcon: {
        fontSize: 24,
    },
    barText: {
        fontSize: 15,
        fontWeight: "500",
        marginRight: 7,
    },
    barIconSmall: {
        fontSize: 17,
    },
    searchInputWrap: {
        paddingHorizontal: 12,
        borderRadius: 15,
        paddingVertical: 10,
        flex: 1
    },
    searchInput: {
        color: '#ffffff',
        outline: 0,
        border: 'none'
    },
    contentContainerBig: {
        marginHorizontal: 'auto',
        width: '100%',
        maxWidth: 675
    }
    // rowBack: {
    //   alignItems: "center",
    //   backgroundColor: "red",
    //   flex: 1,
    //   flexDirection: "row",
    //   justifyContent: "space-between",
    //   paddingLeft: 15,
    // },
    // backRightBtn: {
    //   alignItems: "center",
    //   bottom: 0,
    //   justifyContent: "center",
    //   position: "absolute",
    //   top: 0,
    //   width: 75,
    // },
    // backRightBtnRight: {
    //   backgroundColor: "red",
    //   right: 0,
    // },
};
const VIEWABILITY_CONFIG = {
    minimumViewTime: 300,
    waitForInteraction: false,
    viewAreaCoveragePercentThreshold: 60,
};
const Timeline = (props) => {
    //props.route.params = { module: { _id: "5eac44d394b1d38ee1185126" } };
    const feathersContext = useContext(FeathersContext);
    const { trackMixpanelEvent } = useAnalytics();
    const { t, i18n } = useTranslation();
    const isBig = useIsBig();
    const TimelineItems = useRef([]);
    const Alert = useAlert();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [muted, setMuted] = useState(true);
    const [viewable, setViewable] = useState([]);
    const [viewableQueue, setViewableQueue] = useState([]);
    const [floatingActionHidden, setFloatingActionHidden] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [query, setQuery] = useState("");
    const hashtag = props.route.params.hashtag;
    const initialLoad = useCallback(() => {
        setLoading(true);
        props.actions.posts.empty();
        const preparedQuery = {
            moduleId: props.route.params.module._id,
            $sort: {
                pinned: -1,
                createdAt: -1,
            },
        };
        if (hashtag)
            preparedQuery.content = { $like: hashtag, $search: hashtag };
        feathersContext
            .find("posts", {
            query: preparedQuery,
        })
            .then(r => {
            setLoading(false);
            setError(false);
            // setTimeout(() => {
            //   setLoading(false);
            //   setError(true);
            // }, 3000)
            console.log("R", r);
        })
            .catch((e) => {
            console.log("Error", e);
            setLoading(false);
            setError(true);
        });
    }, []);
    const viewableItemsChanged = useCallback((info) => {
        console.log("Viewable item changed", TimelineItems.current);
        const { viewableItems, changed } = info;
        setViewable((prevViewable) => {
            let work = [...prevViewable];
            console.log("Chnaged", changed);
            changed.forEach((obj) => {
                const { isViewable, key, item } = obj;
                if (isViewable) {
                    work.push(item._id);
                    trackMixpanelEvent("postViewed", {
                        postId: item._id,
                        moduleId: item.moduleId
                    });
                }
                else
                    work = work.filter((e) => e != item._id);
            });
            console.log("Now Chnaged", work);
            return work;
        });
    }, []);
    const handleSearch = useCallback((t) => {
        setQuery(t);
        const preparedQuery = {
            ...(t != ""
                ? {
                    moduleId: props.route.params.module._id,
                    $or: [
                        { content: { $like: t, $search: t } },
                        { 'user.firstName': { $like: t, $search: t } },
                        { 'user.lastName': { $like: t, $search: t } },
                    ],
                }
                : { moduleId: props.route.params.module._id }),
            $sort: {
                pinned: -1,
                createdAt: -1,
            },
        };
        if (hashtag)
            preparedQuery.content = { $like: hashtag, $search: hashtag };
        setLoading(true);
        feathersContext
            .find("posts", {
            query: preparedQuery,
        })
            .then(r => {
            setLoading(false);
            setError(false);
            JSON.stringify(r);
        })
            .catch((e) => {
            setLoading(false);
            setError(true);
            console.log("Error", e);
        });
    }, []);
    const onEndReached = useCallback((info) => {
        const items = props.data.posts.items;
        //alert(`Items ${items.length} and total ${props.data.posts.total}`);
        if (items.length < props.data.posts.total) {
            console.log("End was reached", props.data.posts);
            const preparedQuery = {
                _id: {
                    $lt: items[items.length - 1]._id,
                },
                ...(query != ""
                    ? {
                        moduleId: props.route.params.module._id,
                        $or: [
                            { content: { $like: query, $search: query } },
                            { 'user.firstName': { $like: query, $search: query } },
                            { 'user.lastName': { $like: query, $search: query } },
                        ],
                    }
                    : { moduleId: props.route.params.module._id }),
                $sort: {
                    pinned: -1,
                    createdAt: -1,
                },
            };
            if (hashtag)
                preparedQuery.content = { $like: hashtag, $search: hashtag };
            feathersContext.client.service("posts")
                .find({
                query: preparedQuery,
            })
                .then(r => {
                props.actions.posts.append(r.data, items.length + r.total);
            })
                .catch((e) => {
                console.log("Error", e);
            });
        }
    }, [props.data.posts]);
    const editPost = item => {
        props.navigation.navigate("NewPost", {
            module: props.route.params.module,
            post: item,
            initialLoad,
        });
    };
    const deletePost = item => {
        feathersContext.removeAndUpdate('posts', item);
    };
    const downloadImage = async (item) => {
        const url = `https://ubelong-dev2.s3.eu-west-1.amazonaws.com/${item.image}`;
        mediaDownloadHandler(item._id, url).then(r => {
            Alert.alert(t('timeline.imageDownloadedTitle'), t('timeline.imageDownloadedBody'), [
                {
                    text: t('timeline.imageDownloadedOk'),
                    onPress: () => console.log("Cancel Pressed"),
                    style: "cancel",
                },
            ], { cancelable: false });
        });
    };
    const downloadVideo = async (item) => {
        const url = `https://res.cloudinary.com/padcelona-sl/video/upload/w_600,q_60/${item.video}.mp4`;
        mediaDownloadHandler(item._id, url).then(r => {
            Alert.alert(t('timeline.videoDownloadedTitle'), t('timeline.videoDownloadedBody'), [
                {
                    text: t('timeline.videoDownloadedOk'),
                    onPress: () => console.log("Cancel Pressed"),
                    style: "cancel",
                },
            ], { cancelable: false });
        });
    };
    // const renderHiddenItem = useCallback(
    //   () => (
    //     <View style={styles.rowBack}>
    //       <View style={[styles.backRightBtn, styles.backRightBtnRight]}>
    //         <StyledText style={styles.backTextWhite}>Delete</StyledText>
    //       </View>
    //     </View>
    //   ),
    //   []
    // );
    useEffect(() => {
        initialLoad();
    }, [props.route.params.timelineRefreshStamp, hashtag]);
    const backButton = () => (<View style={isBig ? styles.desktopBackOuter : {}}>
      <TouchableOpacity style={[styles.barButton, isBig ? styles.desktopBackWrap : {}]} onPress={() => {
        props.navigation.goBack();
        if (props.route.params.onGoBack)
            props.route.params.onGoBack();
    }}>
        <StyledText>
          <BelongIcon name="md-cheveron-left" style={{
        ...styles.barIcon,
        color: '#ffffff',
    }}/>
        </StyledText>
      </TouchableOpacity>
    </View>);
    return (<>
      <MediaQuery minWidth={800}>
        <BigHeader navigation={props.navigation}>
          {props.services.app.manifest.title}
        </BigHeader>
      </MediaQuery>
      <CollapsibleWrapper headerComponent={(disappearingOpacity, appearingOpacity) => (<React.Fragment>
            <MediaQuery minWidth={800}>
              <View style={styles.headerDesktop}>
                {backButton()}
                <StyledText variant="h1" style={styles.title}>
                  {hashtag || props.route.params.module.title}
                </StyledText>
                <View style={styles.desktopSearchWrapOuter}>
                  <View style={styles.desktopSearchWrap}>
                    <TextInput placeholderTextColor='#ffffff' onChangeText={(t) => handleSearch(t)} value={query} style={styles.searchInput} placeholder={t('timeline.searchPlaceholder')}/>
                    <BelongIcon name="md-zoom-out" style={{
        ...styles.barIconSmall,
        alignSelf: 'center',
        color: '#ffffff',
    }}/>
                  </View>
                </View>
              </View>
            </MediaQuery>
            <MediaQuery maxWidth={799}>
              <View style={styles.headerContainer}>
                <Animated.View style={{ ...styles.headerUpper, opacity: disappearingOpacity }}>
                  <StyledText variant="h1" style={styles.title}>
                    {hashtag || props.route.params.module.title}
                  </StyledText>
                </Animated.View>
                <View style={styles.headerLower}>
                  {backButton()}
                  {isSearching ?
        <>
                      <View style={{
            ...styles.searchInputWrap, backgroundColor: hextorgba('#ffffff', 0.1)
        }}>
                        <TextInput placeholderTextColor='#ffffff' onChangeText={(t) => handleSearch(t)} value={query} style={styles.searchInput} placeholder={t('timeline.searchPlaceholder')}/>
                      </View>
                      <TouchableOpacity onPress={() => {
            setIsSearching(false);
            handleSearch("");
        }} style={{
            ...styles.barButton,
            paddingHorizontal: 12
        }}>
                        <StyledText style={{
            ...styles.barText,
            color: '#ffffff',
            marginRight: 0
        }} tkey="timeline.cancel">
                          Cancelar
                        </StyledText>
                      </TouchableOpacity>
                    </>
        :
            <TouchableOpacity onPress={() => setIsSearching(true)} style={{
                ...styles.barButton,
                paddingHorizontal: 12,
                backgroundColor: hextorgba('#ffffff', 0.1),
            }}>
                      <StyledText style={{
                ...styles.barText,
                color: '#ffffff',
            }} tkey="timeline.search">
                        Buscar
                      </StyledText>
                      <BelongIcon name="md-zoom-out" style={{
                ...styles.barIconSmall,
                color: '#ffffff',
            }}/>
                    </TouchableOpacity>}
                </View>
              </View>
            </MediaQuery>
          </React.Fragment>)} backgroundColor="#EEEFF3" bodyBackgroundColor="#FFFFFF" headerComponentStyle={{
        backgroundColor: props.services.app.manifest.themeColor,
        paddingHorizontal: 15,
    }} bodyComponentStyle={{
        flex: 1,
    }} scrollViewOverride={(onScroll, scrollEventThrottle, contentContainerPadding) => (loading || error ?
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', paddingTop: contentContainerPadding }}>
              <BelongSpinner error={error}/>
            </View>
        :
            <FlatList contentContainerStyle={{ paddingTop: contentContainerPadding + (isBig ? 50 : 0), ...(isBig ? styles.contentContainerBig : {}) }} onScroll={onScroll} scrollEventThrottle={scrollEventThrottle} viewabilityConfig={VIEWABILITY_CONFIG} removeClippedSubviews={true} onViewableItemsChanged={viewableItemsChanged} initialNumToRender={3} maxToRenderPerBatch={3} windowSize={5} onEndReached={onEndReached} onEndReachedThreshold={0.1} style={{}} data={props.data.posts.items} renderItem={({ item }) => {
                if (!item.postType || item.postType == 'user') {
                    return (<TimelineItem getRef={(ref) => {
                        //console.log("Viewable I SET REF", ref);
                        TimelineItems.current[item._id] = ref;
                    }} key={item._id} item={item} isMine={item.userId === props.services.app.feathersUser._id} editPost={() => editPost(item)} deletePost={() => deletePost(item)} downloadImage={() => downloadImage(item)} downloadVideo={() => downloadVideo(item)} viewable={viewable.indexOf(item._id) != -1} muted={muted} toggleMuted={() => setMuted((oldMuted) => !oldMuted)} onLike={() => {
                        if (item.userLikes) {
                            return feathersContext.patchAndUpdate("posts", item._id, {
                                toggleLike: false,
                            });
                        }
                        else {
                            return feathersContext.patchAndUpdate("posts", item._id, {
                                toggleLike: true,
                            });
                        }
                    }} setHashtag={(hashtag) => {
                        if (props.route.params.hashtag) {
                            props.navigation.navigate(`Module_timelineModules`, {
                                module: props.route.params.module,
                                hashtag
                            });
                        }
                        else {
                            props.navigation.push(`Module_timelineModules`, {
                                module: props.route.params.module,
                                hashtag,
                                onGoBack: initialLoad
                            });
                        }
                    }} themeColor={props.services.app.manifest.themeColor} navigation={props.navigation} t={t}/>);
                }
                else {
                    return (<SurveyItem item={item}/>);
                }
            }}/>)}></CollapsibleWrapper>
      <FloatingAction onPress={() => props.services.app.feathersAuthenticated
        ? props.navigation.navigate("NewPost", {
            module: props.route.params.module,
            initialLoad,
        })
        : null} hidden={floatingActionHidden}>{t('timeline.newPost')}</FloatingAction>
    </>);
};
export default connect((state) => ({
    data: {
        posts: state.data.posts,
    },
    services: {
        app: state.services.app,
    },
}), (dispatch) => ({
    actions: {
        posts: bindActionCreators(postsActionCreators, dispatch),
    },
}))(Timeline);
