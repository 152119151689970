import React from "react";
// REACT NATIVE COMPONENTS
import { TouchableOpacity } from "react-native";
// OWN ROOT COMPONENTS
import StyledText from "../../../../../components/StyledText";
// OTHER UTILS
import workingLocale from "../../../../../services/locale";
import { moment } from '../../../../../i18n';
const locale = workingLocale();
const votedImage = require('../../../../../img/voted.png');
// STYLING
const styles = {
    container: {
        flexDirection: "row",
        alignItems: "center",
        borderBottomWidth: 1,
        borderBottomColor: "#E9E9E9",
        marginHorizontal: 15,
        paddingHorizontal: 10,
        paddingVertical: 25
    },
    title: {
        fontSize: 17,
        lineHeight: 21,
        fontWeight: "500",
        color: "#333333",
        flex: 1
    },
    bullet: {
        width: 24,
        height: 24,
        borderRadius: 12,
        borderWidth: 1,
        borderColor: '#CACACA',
        backgroundColor: '#EEEFF3'
    }
};
export default ({ themeColor, key, index, item, onPress = () => { } }) => {
    return (<TouchableOpacity key={key} onPress={onPress} style={styles.container}>
      <StyledText style={styles.title}>{`Open Session ${index + 1} (${moment(item.startDate).format('HH:mm')}h)`}</StyledText>
      

    </TouchableOpacity>);
};
