import React, { useContext, useCallback, useState } from "react";
// REACT NATIVE COMPONENTS
import { View, TextInput, Image, TouchableWithoutFeedback, Platform } from "react-native";
import * as Progress from "react-native-progress";
// OWN ROOT COMPONENTS
import KeyboardWrapper from "../../../../components/KeyboardWrapper";
import SmallHeader from "../../../../components/SmallHeader";
import ScaledImage from "../../../../components/ScaledImage";
import StyledText from "../../../../components/StyledText";
import Suggestor from './components/Suggestor';
import UploadHandler from './components/UploadHandler';
// WRAPPERS AND CONTEXT CREATORS
import { FeathersContext } from "../../../../services/feathers/FeathersProvider";
// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
// OTHER UTILS
import { useForm, Controller } from "react-hook-form";
import { MentionInput, parseValue } from 'react-native-controlled-mentions';
import { useTranslation } from 'react-i18next';
import { useIsBig } from '../../../../components/BelongResponsive';
// STYLING
const styles = {
    backdrop: {
        backgroundColor: 'rgba(0,0,0,0.7)',
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        overflow: 'hidden'
    },
    container: {
        backgroundColor: "#EEEFF3",
        flex: 1,
        position: "relative",
    },
    containerBig: {
        position: 'absolute',
        overflow: 'hidden',
        width: '100%',
        maxWidth: 600,
        minHeight: 600,
        alignSelf: 'center',
        bottom: -10,
        borderRadius: 10,
        shadowColor: "#000000",
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.08,
        shadowRadius: 4,
    },
    input: {
        paddingHorizontal: 15,
        backgroundColor: "#ffffff",
        outline: 'none',
        flexGrow: 1
    },
    attachments: {
        flexDirection: "row",
        backgroundColor: "#fff",
        flex: 1,
        paddingHorizontal: 15,
        paddingTop: 20,
    },
    attachment: {
        width: 78,
        height: 78,
        borderRadius: 10,
    },
    attachmentWrap: {
        position: "absolute",
        bottom: 20,
        width: "100%",
        left: 0,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    attachmentButton: {
        backgroundColor: "red",
        width: 60,
        height: 60,
        borderRadius: 30,
        justifyContent: "center",
        alignItems: "center",
    },
};
const NewPost = (props) => {
    const [videoThumb, setVideoThumb] = useState(null);
    const [inputHeight, setInputHeight] = useState(0);
    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(false);
    // FORM CONTEXT LOAD
    const { control, handleSubmit, register, errors, reset, setValue, watch, getValues } = useForm();
    // FEATHERS CONTEXT LOAD
    const feathersContext = useContext(FeathersContext);
    const { t, i18n } = useTranslation();
    const isBig = useIsBig();
    const onSubmit = useCallback((data) => {
        if (props.route.params.post) {
            feathersContext
                .patch("posts", props.route.params.post._id, {
                ...data
            })
                .then((r) => {
                setProgress(0);
                setLoading(false);
                setVideoThumb(null);
                reset();
                props.navigation.goBack();
                props.route.params.initialLoad();
            })
                .catch((e) => {
                console.log("Feathers error", e);
            });
        }
        else {
            feathersContext
                .create("posts", {
                ...data,
                moduleId: props.route.params.module._id,
            })
                .then((r) => {
                setProgress(0);
                setLoading(false);
                setVideoThumb(null);
                reset();
                props.navigation.goBack();
                props.route.params.initialLoad();
            })
                .catch((e) => {
                console.log("Feathers error", e);
            });
        }
    }, [props.route.params.post]);
    const existingPost = props.route.params.post;
    return (<TouchableWithoutFeedback onPress={() => props.navigation.goBack()}>
      <View style={styles.backdrop}>
        <KeyboardWrapper style={{ ...styles.container, ...(isBig ? styles.containerBig : {}) }}>
          <SmallHeader hasClose={isBig} backAction={() => props.navigation.goBack()} title={existingPost ? t('timeline.editPost') : t('timeline.newPost')} mainAction={handleSubmit(onSubmit)} mainActionTitle={t('timeline.submit')} themeColor={props.services.app.manifest.themeColor}/>
          <Controller control={control} name="mentions" defaultValue={existingPost?.mentions || []}/>
          <Controller control={control} render={({ onChange, onBlur, value }) => (Platform.OS == 'web' ?
        <TextInput textAlignVertical='top' multiline numberOfLines={15} style={styles.input} onBlur={onBlur} onChangeText={(value) => {
            onChange(value);
            const { parts } = parseValue(value, [
                {
                    trigger: '@',
                    textStyle: { fontWeight: '500', color: 'red' },
                }
            ]);
            setValue('mentions', parts.filter(p => p.partType).map(p => p.data.id));
        }} onContentSizeChange={(event) => {
            setInputHeight(event.nativeEvent.contentSize.height);
        }} value={value} placeholder={t('timeline.newPostPlaceholder')}/>
        :
            <MentionInput textAlignVertical='top' multiline numberOfLines={15} style={styles.input} onBlur={onBlur} onChange={(value) => {
                onChange(value);
                const { parts } = parseValue(value, [
                    {
                        trigger: '@',
                        textStyle: { fontWeight: '500', color: 'red' },
                    }
                ]);
                setValue('mentions', parts.filter(p => p.partType).map(p => p.data.id));
            }} onContentSizeChange={(event) => {
                setInputHeight(event.nativeEvent.contentSize.height);
            }} value={value} placeholder={t('timeline.newPostPlaceholder')} partTypes={[
                {
                    trigger: '@',
                    renderSuggestions: (sp) => <Suggestor {...sp}/>,
                    textStyle: { fontWeight: '500', color: props.services.app.manifest.themeColor },
                },
            ]}/>)} name="content" rules={{ validate: value => {
            if (!value && !getValues('image') && !getValues('video'))
                return 'Req';
            return true;
        } }} defaultValue={existingPost?.content || ""}/>
          <View style={styles.attachments}>
            {loading &&
        (progress < 100 ? (<Progress.Circle size={80} showsText 
        // formatText={(progress) => {
        //   if (progress == 1) return "Procesando...";
        //   else return Math.round(progress * 100.0);
        // }}
        // textStyle={progress == 1 ? { fontSize: 9 } : {}}
        progress={progress} color={props.services.app.manifest.themeColor} style={{ marginBottom: 20 }}/>) : (<StyledText tkey="timeline.newPostLoading" style={{ color: "#ccc", fontSize: 16 }}>
                  Procesando...
                </StyledText>))}
            <Controller control={control} render={({ onChange, onBlur, value }) => value ? (<ScaledImage image={value} fit="cover" width={900} height={900} style={styles.attachment}/>) : null} name="image" rules={{}} defaultValue={existingPost?.image || null}/>
            <Controller control={control} render={({ onChange, onBlur, value }) => videoThumb && (<Image source={videoThumb ? { uri: videoThumb } : null} style={styles.attachment}/>)} name="video" rules={{}} defaultValue={existingPost?.video || null}/>
            <Controller control={control} render={({ onChange, onBlur, value }) => null} name="dimensions" rules={{}} defaultValue={{ width: null, height: null }}/>
          </View>
          <View style={styles.attachmentWrap}>
            <UploadHandler setLoading={setLoading} setValue={setValue} setProgress={setProgress} setVideoThumb={setVideoThumb} buttonStyle={styles.attachmentButton} themeColor={props.services.app.manifest.themeColor} app={props.services.app}/>
          </View>
        </KeyboardWrapper>
      </View>
    </TouchableWithoutFeedback>);
};
export default connect((state) => ({
    services: {
        app: state.services.app,
    },
}), null)(NewPost);
