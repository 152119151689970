import React, { useState, useEffect, useContext } from 'react';
// REACT NATIVE COMPONENTS
import { View, FlatList } from "react-native";
// OWN ROOT COMPONENTS
import CollapsibleWrapper from '../../../../components/CollapsibleWrapper';
import CollapsibleHeader from '../../../../components/CollapsibleHeader';
import StyledText from '../../../../components/StyledText';
import ListItem from "./components/ListItem";
import BelongSpinner from '../../../../components/BelongSpinner';
// WRAPPERS AND CONTEXT CREATORS
import { FeathersContext } from "../../../../services/feathers/FeathersProvider";
// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
// OTHER UTILS
import { useTranslation } from 'react-i18next';
// STYLING
const styles = {
    title: {
        fontSize: 30,
        fontWeight: "500",
        color: "#FFFFFF",
        marginTop: 5,
    },
};
const Component = props => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [fullModule, setFullModule] = useState(null);
    const [appointments, setAppointments] = useState([]);
    const [blockItems, setBlockItems] = useState([]);
    // FEATHERS CONTEXT LOAD
    const feathersContext = useContext(FeathersContext);
    const { t, i18n } = useTranslation();
    useEffect(() => {
        setBlockItems(props.route.params.block.items.sort((a, b) => a._id > b._id));
    }, [props.route.params.block.items]);
    const joinedItem = blockItems.find(i => i.attendees.includes(props.services.app.feathersUser._id));
    return (props.route.params.block ?
        <CollapsibleWrapper headerComponent={(disappearingOpacity, appearingOpacity) => (<CollapsibleHeader negative central={<StyledText variant="h1" style={styles.title}>
                {props.route.params.title}
              </StyledText>} backAction={() => {
            props.route.params.initialLoad();
            props.navigation.goBack();
        }} 
        // mainAction={() => props.actions.notifications.readAll()}
        // mainActionTitle="Leer todas"
        disappearingOpacity={disappearingOpacity} appearingOpacity={appearingOpacity} themeColor={props.services.app.manifest.themeColor}/>)} backgroundColor="#EEEFF3" bodyBackgroundColor="#FFFFFF" headerComponentStyle={{
            backgroundColor: props.services.app.manifest.themeColor,
            paddingHorizontal: 15,
        }} bodyComponentStyle={{
            flex: 1,
        }} scrollViewOverride={(onScroll, scrollEventThrottle, contentContainerPadding) => (loading || error ?
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', paddingTop: contentContainerPadding }}>
              <BelongSpinner error={error}/>
            </View>
            :
                <View style={{
                    ...styles.list,
                    backgroundColor: '#ffffff',
                    flex: 1
                }}>
              <FlatList contentContainerStyle={{ paddingTop: contentContainerPadding }} onScroll={onScroll} scrollEventThrottle={scrollEventThrottle} keyExtractor={(item, index) => index} style={{}} data={blockItems} renderItem={({ key, item, index }) => (<ListItem key={key} index={index} item={item} joined={joinedItem && joinedItem._id == item._id} onPress={() => {
                    item.voted ||
                        props.navigation.navigate("AgendaDetail", {
                            canJoin: joinedItem && joinedItem._id != item._id ? false : true,
                            item,
                            setItem: (item) => setBlockItems(bi => ([...bi.filter(i => i._id != item._id), item].sort((a, b) => a._id > b._id))),
                            title: item.title
                        });
                }}/>)}/>
            </View>)}/>
        : null);
};
export default connect((state) => ({
    services: {
        app: state.services.app,
    },
}), null)(Component);
