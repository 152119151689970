import React, { useState, useContext } from 'react';
// REACT NATIVE COMPONENTS
import { View, ScrollView, TouchableOpacity, Image, TextInput } from "react-native";
// OWN ROOT COMPONENTS
import StyledText from '../../../../components/StyledText';
import BelongButton from '../../../../components/BelongButton';
import BelongIcon from '../../../../components/BelongIcon';
import BigHeader from "../../../../components/BelongHeader/big";
// WRAPPERS AND CONTEXT CREATORS
import { FeathersContext } from "../../../../services/feathers/FeathersProvider";
// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
// OTHER UTILS
import { useTranslation } from 'react-i18next';
import MediaQuery, { useIsBig } from '../../../../components/BelongResponsive';
import greyStar from '../../../../img/star-grey.png';
import yellowStar from '../../../../img/star-yellow.png';
// STYLING
const styles = {
    headerDesktop: {
        height: 100,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    desktopBackOuter: {
        flex: 1,
        alignSelf: 'stretch',
        flexDirection: 'row',
        alignItems: 'center'
    },
    desktopBackWrap: {
        backgroundColor: 'rgba(55,66,96,0.05)',
        width: 36,
        height: 36,
        borderRadius: 18,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    barButton: {
        height: 30,
        borderRadius: 15,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    contentContainerBig: {
        marginHorizontal: 'auto',
        width: '100%',
        maxWidth: 675,
        alignSelf: 'center',
        marginTop: 50,
        backgroundColor: '#ffffff',
        borderRadius: 20
    },
    question: {
        backgroundColor: 'bl',
    },
    furtherQuestion: {
        borderTopRightRadius: 20,
        borderTopLeftRadius: 20,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: -4,
        },
        shadowOpacity: 0.08,
        shadowRadius: 14,
    },
    header: {
        minHeight: 215,
        paddingVertical: 30,
        paddingHorizontal: 40,
        flexDirection: 'column',
        alignItems: "center",
        justifyContent: "center",
        position: 'relative'
    },
    barButton: {
        position: 'absolute',
        // bottom: 10,
        left: 15
    },
    barIcon: {
        fontSize: 24,
        marginRight: 3,
    },
    questionTitle: {
        fontSize: 25,
        lineHeight: 30,
        fontWeight: "500",
        color: '#FFFFFF',
        textAlign: 'center',
        marginTop: 35
    },
    questionType: {
        fontSize: 13,
        color: '#FFFFFF',
        opacity: .6,
        marginTop: 20
    },
    questionIndex: {
        fontSize: 16,
        fontWeight: "500",
        color: '#FFFFFF',
        opacity: .6,
        marginTop: 20
    },
    answer: {
        backgroundColor: '#EEEFF3',
        // borderTopRightRadius: 20,
        // borderTopLeftRadius: 20,
        paddingBottom: 40,
        elevation: 0
    },
    option: {
        marginTop: 15,
        paddingVertical: 22,
        borderRadius: 10,
        marginHorizontal: 15,
        backgroundColor: '#FFFFFF',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        flexDirection: 'row'
    },
    optionBullet: {
        width: 16,
        height: 16,
        borderRadius: 8,
        borderColor: '#979797',
        borderWidth: 1,
        marginHorizontal: 10,
    },
    selectedOptionBullet: {
        width: 16,
        height: 16,
        borderRadius: 8,
        borderColor: '#FFFFFF',
        borderWidth: 1,
        marginHorizontal: 10,
        alignItems: 'center',
        justifyContent: 'center'
    },
    optionBulletInner: {
        width: 8,
        height: 8,
        borderRadius: 4,
        backgroundColor: '#FFFFFF'
    },
    optionText: {
        fontSize: 17,
        lineHeight: 22,
        marginTop: -3,
        fontWeight: "500",
        color: '#333333',
        flex: 1,
        marginRight: 15
    },
    selectedOptionText: {
        color: '#FFFFFF'
    },
    stars: {
        marginTop: 40,
        flexDirection: 'row',
        justifyContent: 'center',
        marginHorizontal: 60,
    },
    star: {
        marginHorizontal: 4,
    },
    starImage: {
        width: 33,
        height: 30
    },
    openInputWrap: {
        marginHorizontal: 15,
        marginTop: 15,
        backgroundColor: '#ffffff',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        borderRadius: 10,
        padding: 20,
    },
    openInput: {
        height: 225,
        border: 'none',
        outline: 'none'
    },
    actions: {
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 70
    }
};
const Component = props => {
    // FEATHERS CONTEXT LOAD
    const feathersContext = useContext(FeathersContext);
    const { t, i18n } = useTranslation();
    const isBig = useIsBig();
    const { block, moduleId } = props.route.params;
    const [answers, setAnswers] = useState(block.questions.map(q => q._id).reduce((acc, curr) => (acc[curr] = '', acc), {}));
    const [submitting, setSubmitting] = useState(false);
    const setRating = (id, rating) => {
        setAnswers(a => ({ ...a, [id]: { rating, answered: true } }));
    };
    const setOption = (id, option) => {
        setAnswers(a => ({ ...a, [id]: { optionId: option._id, answered: true } }));
    };
    const setOpen = (id, text) => {
        if (text && text != '')
            setAnswers(a => ({ ...a, [id]: { content: text, answered: true } }));
        else
            setAnswers(a => ({ ...a, [id]: {} }));
    };
    const typePhrase = question => {
        switch (question.type) {
            case 'options':
                return t('voting.optionsTitle');
            case 'rating':
                return t('voting.ratingTitle');
            case 'open':
                return t('voting.openTitle');
            default:
                return '';
        }
    };
    const allAnswered = () => {
        return Object.keys(answers).filter(a => answers[a].answered).length == block.questions.length;
    };
    const constructVotes = () => Object.keys(answers).map(ak => {
        const answer = answers[ak];
        const object = {
            appId: props.services.app.manifest.slug,
            moduleId: moduleId,
            questionId: ak,
        };
        if (answer.optionId)
            object.optionId = answer.optionId;
        if (answer.rating)
            object.rating = answer.rating;
        if (answer.content)
            object.content = answer.content;
        return object;
    });
    const vote = () => {
        //alert(JSON.stringify(constructVotes()))
        setSubmitting(true);
        feathersContext.client.service("votes").create(constructVotes()).then(r => {
            setSubmitting(false);
            props.navigation.goBack();
            props.route.params.initialLoad();
        }).catch(e => {
            setSubmitting(false);
            alert(JSON.stringify(e));
        });
    };
    return (<React.Fragment>
      <MediaQuery minWidth={800}>
        <BigHeader navigation={props.navigation}>
          {props.services.app.manifest.title}
        </BigHeader>
        <View style={{ ...styles.headerDesktop, backgroundColor: props.services.app.manifest.themeColor }}>
          <View style={styles.desktopBackOuter}>
            <TouchableOpacity style={[styles.barButton, isBig ? styles.desktopBackWrap : {}]} onPress={() => {
        props.navigation.goBack();
        if (props.route.params.onGoBack)
            props.route.params.onGoBack();
    }}>
              <StyledText>
                <BelongIcon name="md-cheveron-left" style={{
        ...styles.barIcon,
        color: '#ffffff',
    }}/>
              </StyledText>
            </TouchableOpacity>
          </View>
          <StyledText variant="h1" style={{ ...styles.title, color: '#FFFFFF' }}>
            {props.route.params.block.title}
          </StyledText>
          <View style={{ flex: 1 }}>
          </View>
        </View>
      </MediaQuery>
      <ScrollView style={{ backgroundColor: '#EEEFF3' }} contentContainerStyle={isBig ? styles.contentContainerBig : {}}>
        {block.questions.map((q, k) => (<View key={q._id} style={{ ...styles.question, ...(k == 0 ? {} : styles.furtherQuestion), ...(k == block.questions.length - 1 ? { shadowOpacity: 0 } : {}) }}>
            <View style={{ ...styles.header, backgroundColor: props.services.app.manifest.themeColor }}>

              <StyledText style={styles.questionTitle}>{q.title}</StyledText>
              <StyledText style={styles.questionType}>{typePhrase(q)}</StyledText>
              <StyledText style={{ ...styles.questionIndex }}>{k + 1} / {block.questions.length}</StyledText>
              {k == 0 && !isBig &&
        <TouchableOpacity style={styles.barButton} onPress={() => props.navigation.goBack()}>
                  <BelongIcon name="md-cheveron-left" style={{
            ...styles.barIcon,
            color: '#FFFFFF',
        }}/>
                </TouchableOpacity>}
            </View>
            <View style={{ ...styles.answer, ...(isBig ? { backgroundColor: '#ffffff' } : {}) }}>
              {q.type == 'options' &&
        q.options.map(o => (<TouchableOpacity onPress={() => setOption(q._id, o)} key={o._id} style={{ ...styles.option, ...(answers[q._id].optionId == o._id ? { backgroundColor: props.services.app.manifest.themeColor } : {}) }}>
                    {answers[q._id].optionId == o._id ?
            <View style={styles.selectedOptionBullet}>
                        <View style={styles.optionBulletInner}/>
                      </View>
            :
                <View style={styles.optionBullet}/>}

                    <StyledText style={{ ...styles.optionText, ...(answers[q._id].optionId == o._id ? styles.selectedOptionText : {}) }}>{o.title}</StyledText>
                  </TouchableOpacity>))}
              {q.type == 'rating' &&
        <View style={styles.stars}>
                  <TouchableOpacity onPress={() => setRating(q._id, 1)} style={styles.star}><Image source={answers[q._id].rating > 0 ? yellowStar : greyStar} style={styles.starImage}/></TouchableOpacity>
                  <TouchableOpacity onPress={() => setRating(q._id, 2)} style={styles.star}><Image source={answers[q._id].rating > 1 ? yellowStar : greyStar} style={styles.starImage}/></TouchableOpacity>
                  <TouchableOpacity onPress={() => setRating(q._id, 3)} style={styles.star}><Image source={answers[q._id].rating > 2 ? yellowStar : greyStar} style={styles.starImage}/></TouchableOpacity>
                  <TouchableOpacity onPress={() => setRating(q._id, 4)} style={styles.star}><Image source={answers[q._id].rating > 3 ? yellowStar : greyStar} style={styles.starImage}/></TouchableOpacity>
                  <TouchableOpacity onPress={() => setRating(q._id, 5)} style={styles.star}><Image source={answers[q._id].rating > 4 ? yellowStar : greyStar} style={styles.starImage}/></TouchableOpacity>
                </View>}
              {q.type == 'open' &&
        <View style={styles.openInputWrap}>
                  <TextInput multiline={true} style={styles.openInput} placeholder={t('voting.openPrompt')} value={answers[q._id].content} onChangeText={(t) => setOpen(q._id, t)}/>
                </View>}
            </View>
          </View>))}
        <View style={styles.actions}>
          <BelongButton style={{ backgroundColor: props.services.app.manifest.themeColor }} tkey="voting.submit" disabled={!allAnswered() || submitting} onPress={vote}>Responder</BelongButton>
        </View>
      </ScrollView>
    </React.Fragment>);
};
export default connect((state) => ({
    services: {
        app: state.services.app,
    },
}), null)(Component);
