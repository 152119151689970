import React, { useState, useEffect, useContext } from 'react';
// REACT NATIVE COMPONENTS
import { View, FlatList } from "react-native";
// OWN ROOT COMPONENTS
import CollapsibleWrapper from '../../../components/CollapsibleWrapper';
import CollapsibleHeader from '../../../components/CollapsibleHeader';
import StyledText from '../../../components/StyledText';
import ListItem from "./components/ListItem";
import BelongSpinner from '../../../components/BelongSpinner';
// WRAPPERS AND CONTEXT CREATORS
import { FeathersContext } from "../../../services/feathers/FeathersProvider";
// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
// OTHER UTILS
import { useTranslation } from 'react-i18next';
import { moment } from '../../../i18n';
// STYLING
const styles = {
    title: {
        fontSize: 30,
        fontWeight: "500",
        color: "#FFFFFF",
        marginTop: 5,
    },
};
const Component = props => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [fullModule, setFullModule] = useState(null);
    const [appointments, setAppointments] = useState([]);
    // FEATHERS CONTEXT LOAD
    const feathersContext = useContext(FeathersContext);
    const { t, i18n } = useTranslation();
    const initialLoad = () => {
        setLoading(true);
        setFullModule(null);
        feathersContext
            .get("modules", props.route.params.module._id)
            .then((r) => {
            setFullModule(r);
            setLoading(false);
            setError(false);
            // setTimeout(() => {
            //   setLoading(false);
            //   setError(true);
            // }, 3000)
        })
            .catch((e) => {
            console.log(e);
            setLoading(false);
            setError(true);
        });
        feathersContext.onlyFind('appointments', {
            query: {
                onlyOptional: 1
            }
        }).then(r => {
            setAppointments(r.data);
        });
    };
    useEffect(() => {
        initialLoad();
    }, []);
    const appointmentGroups = appointments.reduce((acc, val) => {
        const endDate = moment.max(moment(val.endDate), moment(val.startDate));
        let index = -1;
        acc.map((g, i) => {
            if (g.startDate.isBefore(endDate, 'minute') && g.endDate.isAfter(val.startDate, 'minute'))
                index = i;
        });
        //console.log("Indess", index)
        // If there is a group already
        if (index != -1) {
            acc[index].items.push(val);
            acc[index].startDate = moment.min([moment(acc[index].startDate), moment(val.startDate)]);
            acc[index].endDate = moment.max([moment(acc[index].endDate), moment(endDate)]);
        }
        else {
            acc.push({
                startDate: moment(val.startDate),
                endDate: moment(endDate),
                items: [val]
            });
        }
        return acc;
    }, []);
    return (fullModule ?
        <CollapsibleWrapper headerComponent={(disappearingOpacity, appearingOpacity) => (<CollapsibleHeader negative central={<React.Fragment>
                <StyledText variant="h1" style={styles.title}>
                  {props.route.params.module.title}
                </StyledText>
                <StyledText style={{ marginHorizontal: 30, color: '#FFFFFF', marginTop: 10, textAlign: 'center', fontSize: 12, opacity: .8 }} tkey="agenda.choiceSubtitle"></StyledText>
              </React.Fragment>} backAction={() => props.navigation.goBack()} 
        // mainAction={() => props.actions.notifications.readAll()}
        // mainActionTitle="Leer todas"
        disappearingOpacity={disappearingOpacity} appearingOpacity={appearingOpacity} themeColor={props.services.app.manifest.themeColor}/>)} backgroundColor="#EEEFF3" bodyBackgroundColor="#FFFFFF" headerComponentStyle={{
            backgroundColor: props.services.app.manifest.themeColor,
            paddingHorizontal: 15,
        }} bodyComponentStyle={{
            flex: 1,
        }} scrollViewOverride={(onScroll, scrollEventThrottle, contentContainerPadding) => (loading || error ?
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', paddingTop: contentContainerPadding }}>
              <BelongSpinner error={error}/>
            </View>
            :
                <View style={{
                    ...styles.list,
                    backgroundColor: '#ffffff',
                    flex: 1
                }}>
              <FlatList contentContainerStyle={{ paddingTop: contentContainerPadding }} onScroll={onScroll} scrollEventThrottle={scrollEventThrottle} keyExtractor={(item, index) => index} style={{}} data={appointmentGroups} renderItem={({ key, item, index }) => (<ListItem key={key} index={index} item={item} onPress={() => {
                    item.voted ||
                        props.navigation.navigate("AgendaChoiceBlock", {
                            title: `Open Session ${index + 1} (${moment(item.startDate).format('HH:mm')}h)`,
                            block: item,
                            initialLoad: initialLoad,
                        });
                }}/>)}/>
            </View>)}/>
        : null);
};
export default connect((state) => ({
    services: {
        app: state.services.app,
    },
}), null)(Component);
