import React from 'react';
import { View, Text, StatusBar, Platform } from 'react-native';
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { SafeAreaProvider } from "react-native-safe-area-context";
// Imports: Redux Persist Persister
import { store, persistor } from './store';
import * as appActionCreators from "./services/app/actions";
import FeathersWrap from "./FeathersWrap";
import AlertProvider from './components/BelongAlert';
import { enableScreens } from 'react-native-screens';
enableScreens(false);
class Wrap extends React.Component {
    constructor() {
        super();
        this.onBeforeLift = () => {
            if (['ios', 'android'].indexOf(Platform.OS) === -1 && window.location) {
                const slugs = window.location.pathname.split("/").filter((p) => p !== "");
                if (slugs.length > 0) {
                    const slug = slugs[0];
                    store.dispatch(appActionCreators.set_slug(slug));
                    this.setState({ slug });
                }
            }
            else {
                //store.dispatch(appActionCreators.set_slug(appConfig.appCode));
            }
            this.loadManifest();
        };
        this.loadManifest = () => {
            //appService.loadManifest();
        };
        this.componentDidMount = () => {
            if (window.location.host.includes("pwa.")) {
                window.location.href = window.location.href.replace("pwa.", "app.");
            }
        };
        this.state = {
            slug: ''
        };
    }
    render() {
        return (
        // Redux: Global Store
        <Provider store={store}>
        <PersistGate loading={<Text>Loading...</Text>} onBeforeLift={this.onBeforeLift} persistor={persistor}>
          <SafeAreaProvider>
            <AlertProvider>
              <StatusBar backgroundColor="transparent" barStyle="dark-content"/>
              <View style={{
            height: "100vh",
            /* mobile viewport bug fix */
            maxHeight: "-webkit-fill-available",
            width: '100vw',
            backgroundColor: 'white'
        }}>
                <FeathersWrap slug={this.state.slug}/>
              </View>
            </AlertProvider>
          </SafeAreaProvider>
        </PersistGate>
      </Provider>);
    }
}
;
export default Wrap;
