import React, { useEffect, useContext, useState } from "react";
// REACT NATIVE COMPONENTS
import { View, TouchableOpacity, ScrollView, KeyboardAvoidingView, Platform } from "react-native";
// OWN ROOT COMPONENTS
import KeyboardWrapper from "../../../components/KeyboardWrapper";
import CollapsibleWrapper from "../../../components/CollapsibleWrapper";
import CollapsibleHeader from "../../../components/CollapsibleHeader";
import StyledText from "../../../components/StyledText";
import BelongButton from "../../../components/BelongButton";
import BigHeader from "../../../components/BelongHeader/big";
import BelongIcon from "../../../components/BelongIcon";
import { Form, FormGroup, Input } from "../../../components/Form";
import TopicSelector from "./components/TopicSelector";
// WRAPPERS AND CONTEXT CREATORS
import { FeathersContext } from "../../../services/feathers/FeathersProvider";
import { useAnalytics } from '../../../Analytics';
import { useLocale } from '../../../i18n';
import { useAlert } from "../../../components/BelongAlert";
// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
// OTHER UTILS
import { useForm, Controller } from "react-hook-form";
import validation from "./validation.tsx";
import { useTranslation } from 'react-i18next';
import MediaQuery, { useIsBig } from '../../../components/BelongResponsive';
// STYLING
const styles = {
    inner: {
        flex: 1,
        alignItems: "stretch",
        justifyContent: "space-between"
    },
    titleBlock: {
        alignItems: 'center'
    },
    title: {
        fontSize: 30,
        fontWeight: "500",
        color: "#FFFFFF",
        marginTop: 5,
        textAlign: 'center'
    },
    subtitle: {
        fontSize: 13,
        lineHeight: 19,
        color: "#FFFFFF",
        opacity: .6,
        marginTop: 10,
        textAlign: 'center'
    },
    desktopContainer: {
        marginTop: 20,
        marginHorizontal: 'auto',
        width: '100%',
        maxWidth: 675,
        backgroundColor: '#FFFFFF',
        shadowColor: "#550000",
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        borderRadius: 20,
        paddingBottom: 20
    },
    headerDesktop: {
        height: 100,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    desktopBackWrap: {
        backgroundColor: 'rgba(55,66,96,0.05)',
        width: 36,
        height: 36,
        borderRadius: 18,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    barButton: {
        height: 30,
        borderRadius: 15,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    barIcon: {
        fontSize: 24,
    },
    formGroup: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 0,
        },
        shadowOpacity: 0,
        shadowRadius: 0,
        marginBottom: 0,
        backgroundColor: "#ffffff",
        flex: 1,
    },
    input: {
        paddingTop: 15,
    },
    attachmentWrap: {
        position: "absolute",
        bottom: 20,
        width: "100%",
        left: 0,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    attachmentButton: {
        backgroundColor: "blue",
        width: 60,
        height: 60,
        borderRadius: 30,
        justifyContent: "center",
        alignItems: "center",
    },
    attachmentName: {
        marginLeft: 15,
    },
    loader: {
        position: "absolute",
        backgroundColor: "rgba(0,0,0,0.6)",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        justifyContent: "center",
        alignItems: "center",
    },
    loaderText: {
        fontSize: 22,
        color: "#fff",
    },
};
const Questions = (props) => {
    const [fullModule, setFullModule] = useState({});
    const [topic, setTopic] = useState(null);
    const [attachment, setAttachment] = useState(null);
    const [inputHeight, setInputHeight] = useState(0);
    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(false);
    const isBig = useIsBig();
    // FORM CONTEXT LOAD
    const { control, handleSubmit, errors, reset, setValue, getValues } = useForm({
        defaultValues: {
            content: ''
        }
    });
    // FEATHERS CONTEXT LOAD
    const feathersContext = useContext(FeathersContext);
    const Alert = useAlert();
    const lolcaleContext = useLocale();
    const locale = lolcaleContext.workingLocale();
    const { trackMixpanelEvent } = useAnalytics();
    const { t, i18n } = useTranslation();
    const initialLoad = () => {
        setFullModule({});
        feathersContext
            .get("modules", props.route.params.module._id)
            .then((r) => {
            setFullModule(r);
        })
            .catch((e) => console.log(e));
    };
    useEffect(() => {
        initialLoad();
    }, []);
    // QUESTION SUBMIT HANDLING
    const onSubmit = (data) => {
        // alert(JSON.stringify(data));
        if (data.content && data.content != "") {
            feathersContext
                .create("questions", {
                ...data,
                topicId: topic,
                moduleId: props.route.params.module._id
            })
                .then((r) => {
                setTopic(null);
                trackMixpanelEvent("questionSubmitted", {
                    moduleId: props.route.params.module._id
                });
                reset();
                setAttachment(null);
                Alert.alert(t('questions.thanksTitle'), t('questions.thanksBody'), [
                    {
                        text: t('questions.thanksOk'),
                        onPress: () => console.log("Cancel Pressed"),
                        style: "cancel",
                    },
                ], { cancelable: false });
            });
        }
    };
    const renderForm = () => {
        return (<Form {...{ control, validation, errors }}>
        <FormGroup style={styles.formGroup}>
          <Input multiline numberOfLines={15} containerStyle={{
            //height: Math.max(65, inputHeight + 15),
            flex: 1
        }} style={{ ...styles.input, ...(isBig ? { borderColor: '#D1D1D1', borderRadius: 10, borderWidth: 1, paddingHorizontal: 15 } : {}) }} onContentSizeChange={(event) => {
            // if (['ios','android'].indexOf(Platform.OS) !== -1) setInputHeight(event.nativeEvent.contentSize.height);
            // if (53 == event.nativeEvent.contentSize.height) setInputHeight(event.nativeEvent.contentSize.height);
            // console.log(event.nativeEvent.contentSize.height);
        }} textAlignVertical="top" name="content" placeholder={t('questions.placeholder')} last/>

          <Controller control={control} name="attachment" defaultValue=""/>
          <Controller control={control} name="topicId" defaultValue=""/>
        </FormGroup>
      </Form>);
    };
    return (<KeyboardAvoidingView behavior={Platform.OS == "ios" ? "padding" : "height"} style={styles.inner}>
      <KeyboardWrapper style={{ flex: 1, position: "relative" }}>
        <MediaQuery minWidth={800}>
          <BigHeader navigation={props.navigation}>
            {props.services.app.manifest.title}
          </BigHeader>
        </MediaQuery>
        <CollapsibleWrapper headerComponent={(disappearingOpacity, appearingOpacity) => (<React.Fragment>
              <MediaQuery minWidth={800}>
                <View style={styles.headerDesktop}>
                  <TouchableOpacity style={[styles.barButton, isBig ? styles.desktopBackWrap : {}]} onPress={() => {
        props.navigation.goBack();
        if (props.route.params.onGoBack)
            props.route.params.onGoBack();
    }}>
                    <StyledText>
                      <BelongIcon name="md-cheveron-left" style={{
        ...styles.barIcon,
        color: '#ffffff',
    }}/>
                    </StyledText>
                  </TouchableOpacity>
                  <StyledText variant="h1" style={styles.title}>
                    {props.route.params.module.title}
                  </StyledText>
                  <View>
                  </View>
                </View>
              </MediaQuery>
              <MediaQuery maxWidth={799}>
                <CollapsibleHeader negative central={<View style={styles.titleBlock}>
                      <StyledText variant="h1" style={styles.title}>
                        {props.route.params.module.title}
                      </StyledText>
                      <StyledText style={styles.subtitle}>
                        {fullModule.hint}
                      </StyledText>
                    </View>} backAction={() => props.navigation.goBack()} mainAction={handleSubmit(onSubmit)} mainActionTitle={t('questions.submit')} disappearingOpacity={disappearingOpacity} appearingOpacity={appearingOpacity} themeColor={props.services.app.manifest.themeColor}/>
              </MediaQuery>
            </React.Fragment>)} backgroundColor="#EEEFF3" bodyBackgroundColor="#FFFFFF" headerComponentStyle={{
        backgroundColor: props.services.app.manifest.themeColor,
        paddingHorizontal: 15,
    }} bodyComponentStyle={{
        flex: 1,
    }} scrollViewOverride={(onScroll, scrollEventThrottle, contentContainerPadding) => (<React.Fragment>
              <MediaQuery minWidth={800}>
                <View style={{ ...styles.desktopContainer, marginTop: contentContainerPadding + (isBig ? 50 : 0) }}>
                  {fullModule.topics && fullModule.topics.length > 0 ?
        <View style={{
            backgroundColor: "#fff",
            marginHorizontal: 15,
            marginBottom: 10,
            padding: 10,
            borderRadius: 10,
            marginTop: 15,
            borderWidth: 1,
            borderColor: '#D1D1D1',
            borderRadius: 10
        }}>
                      <TopicSelector topic={topic} fullModule={fullModule} setTopic={setTopic}/>
                    </View>
        : null}
                  {renderForm()}
                  <BelongButton style={{ backgroundColor: props.services.app.manifest.themeColor, alignSelf: 'center' }} onPress={handleSubmit(onSubmit)}>{t('questions.submit')}</BelongButton>
                </View>
              </MediaQuery>
              <MediaQuery maxWidth={799}>
                <ScrollView style={{ flex: 1 }} contentContainerStyle={{
        paddingTop: contentContainerPadding + (isBig ? 50 : 0),
        ...(isBig ? styles.contentContainerBig : {}),
        flex: 1,
    }} onScroll={onScroll} scrollEventThrottle={scrollEventThrottle}>
                  {fullModule.topics && fullModule.topics.length > 0 ?
        <View style={{
            backgroundColor: "#fff",
            marginHorizontal: 15,
            marginBottom: 10,
            padding: 10,
            borderRadius: 10,
            marginTop: 15
        }}>
                      <TopicSelector topic={topic} fullModule={fullModule} setTopic={setTopic}/>
                    </View>
        : null}
                  <View style={{
        backgroundColor: "#fff",
        borderTopLeftRadius: fullModule.topics && fullModule.topics.length > 0 ? 10 : 20,
        borderTopRightRadius: fullModule.topics && fullModule.topics.length > 0 ? 10 : 20,
        marginHorizontal: fullModule.topics && fullModule.topics.length > 0 ? 15 : 0,
        flex: 1,
        overflow: "hidden",
    }}>
                    {renderForm()}
                  </View>
                </ScrollView>
              </MediaQuery>
            </React.Fragment>)}/>
        
        {loading && (<View style={styles.loader}>
            <StyledText tkey="questions.loading" style={styles.loaderText}>Cargando...</StyledText>
          </View>)}

      </KeyboardWrapper>
    </KeyboardAvoidingView>);
};
export default connect((state) => ({
    services: {
        app: state.services.app,
    },
}), null)(Questions);
