import React, { useContext } from 'react';
import { TouchableOpacity } from 'react-native';
import BelongIcon from "../../../../../../components/BelongIcon";
import axios from "axios";
import apiConfig from "../../../../../../services/api/config";
import { FeathersContext } from '../../../../../../services/feathers/FeathersProvider';
export default props => {
    // FEATHERS CONTEXT LOAD
    const feathersContext = useContext(FeathersContext);
    // Create a reference to the hidden file input element
    const hiddenFileInput = React.useRef(null);
    // HANDLE DOCUMENT PICK
    const handleDocumentPick = () => {
        hiddenFileInput.current.click();
    };
    // Call a function (passed as a prop from the parent component)
    // to handle the user-selected file
    const handleChange = async (event) => {
        const fileUploaded = event.target.files[0];
        console.log(event.target.files[0]);
        props.setLoading(true);
        let url = "";
        const formData = new FormData();
        if (fileUploaded.type.includes("image")) {
            props.setValue("video", null);
            formData.append("uri", fileUploaded);
            url = `${apiConfig.url[props.app.env]}directuploads`;
        }
        else if (fileUploaded.type.includes("video")) {
            props.setValue("image", null);
            formData.append("file", fileUploaded);
            formData.append("upload_preset", "r0tgo8i0");
            formData.append("cloud_name", "padcelona-sl");
            url = "https://api.cloudinary.com/v1_1/padcelona-sl/upload";
        }
        else {
            props.setLoading(false);
        }
        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
            onUploadProgress: function (progressEvent) {
                props.setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
            },
        };
        const fileType = fileUploaded.type;
        let imageKey = '';
        async function getHash(blob, algo = "SHA-256") {
            // convert your Blob to an ArrayBuffer
            // could also use a FileRedaer for this for browsers that don't support Response API
            const buf = await new Response(blob).arrayBuffer();
            const hash = await crypto.subtle.digest(algo, buf);
            let result = '';
            const view = new DataView(hash);
            for (let i = 0; i < hash.byteLength; i += 4) {
                result += view.getUint32(i).toString(16).padStart(2, '0');
            }
            return result;
        }
        if (fileType.includes("image")) { // if its s3
            const fileExt = event.target.files[0].name.split('.').pop();
            const hash = await getHash(event.target.files[0]);
            const result = await feathersContext
                .create("directuploads", {
                //key: event.target.files[0].name
                key: `${hash}.${fileExt}`,
                type: fileType
            });
            url = result.signedUrl;
            imageKey = result.key;
            config.headers['content-type'] = fileType;
        }
        axios({
            method: fileType.includes("image") ? 'put' : 'post',
            url,
            data: fileUploaded,
            config
        })
            .then((ar) => {
            console.log("ar", ar);
            if (fileType.includes("image")) {
                //props.setValue("image", ar.data.id);
                props.setValue("image", imageKey);
            }
            else if (fileType.includes("video")) {
                props.setVideoThumb(ar.data.secure_url.replace(/\.([a-zA-Z0-9]{3})($|\?)/, ".jpg$2"));
                props.setValue("video", ar.data.public_id);
                props.setValue("dimensions", {
                    width: ar.data.width,
                    height: ar.data.height,
                });
            }
            //
            props.setLoading(false);
        })
            .catch((e) => {
            console.log("Axios error", e.response);
            props.setLoading(false);
        });
    };
    return (<React.Fragment>
            <TouchableOpacity onPress={() => handleDocumentPick()} style={{
        ...props.buttonStyle,
        backgroundColor: props.themeColor,
    }}>
            <BelongIcon name="md-paper-clip" color="#ffffff" size={30}/>
            </TouchableOpacity>
            <input type="file" ref={hiddenFileInput} onChange={handleChange} style={{ display: 'none' }}/>
        </React.Fragment>);
};
