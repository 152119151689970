import React from "react";
import { View, Dimensions } from "react-native";
import { SafeAreaConsumer } from "react-native-safe-area-context";
import StyledText from "../StyledText";
import { connect } from "react-redux";
import { useIsBig } from '../BelongResponsive';
const styles = {
    wrapper: {
        height: 50,
        flexDirection: "row",
        alignItems: 'center',
    },
    wrapperBig: {
        height: 82
    },
    leftButton: {
        marginLeft: 14
    },
    titleWrap: {
        flex: 1
    },
    title: { textAlign: "center", color: "#666", fontWeight: '500', fontSize: 16 },
    rightButton: {
        marginRight: 14,
        justifyContent: 'center',
        alignItems: 'center'
    },
    menuButton: {
        marginHorizontal: 7,
        flexDirection: 'row',
        alignItems: 'center'
    },
    menuText: {
        color: "#8d8e8f",
        marginLeft: 4
    },
    itemIcon: {
        fontSize: 30,
        color: "#8d8e8f",
    },
};
const withHooksHOC = (Component) => {
    return (props) => {
        const isBig = useIsBig();
        return <Component isBig={isBig} {...props}/>;
    };
};
class BelongHeader extends React.PureComponent {
    constructor(props) {
        super(props);
        this._onParentLayout = event => {
            this.setState({ containerSize: event.nativeEvent.layout.width });
        };
        this._centerViewLayout = event => {
            const windowWidth = this.state.containerSize || Dimensions.get("window").width;
            const position = event.nativeEvent.layout.x;
            const width = event.nativeEvent.layout.width;
            const desiredPosition = (windowWidth - width) / 2;
            console.log("Position is ", position);
            console.log("Width is ", width);
            console.log("Window with is ", windowWidth);
            console.log("Desired position is ", desiredPosition);
            // If it is too far left
            if (desiredPosition > position) {
                this.setState({
                    leftExtra: (desiredPosition - position) * 2,
                    rightExtra: 0
                });
            }
            else if (desiredPosition < position) { // If it's too far right
                this.setState({
                    leftExtra: 0,
                    rightExtra: (position - desiredPosition) * 2
                });
            }
            else {
                this.setState({
                    leftExtra: 0,
                    rightExtra: 0
                });
            }
        };
        this.state = {
            containerSize: false,
            leftExtra: 0,
            rightExtra: 0
        };
    }
    render() {
        return (<SafeAreaConsumer>
        {(insets) => (<View style={{ ...styles.wrapper, ...(this.props.isBig ? { ...styles.wrapperBig, backgroundColor: this.props.services.app.manifest.themeColor } : {}), marginTop: insets.top }} onLayout={this._onParentLayout}>
            <View style={styles.leftButton}>
              <View></View>
            </View>
            <View style={{
            ...styles.titleWrap,
            paddingLeft: this.state.leftExtra,
            paddingRight: this.state.rightExtra,
        }} onLayout={this._centerViewLayout}>
              <StyledText style={{
            ...styles.title,
            color: this.props.services.app.manifest.themeColor,
        }}>
                {this.props.children}
              </StyledText>
            </View>
            <View style={styles.rightButton}>
              <View style={{ flexDirection: 'row' }}>



              </View>
            </View>
          </View>)}
      </SafeAreaConsumer>);
    }
}
export default connect((state) => ({
    services: {
        app: state.services.app,
    },
}), null)(withHooksHOC(BelongHeader));
