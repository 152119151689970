import React from "react";
// REACT NATIVE COMPONENTS
import { TouchableOpacity, View, Image } from "react-native";
// OWN ROOT COMPONENTS
import StyledText from "../../../../../../components/StyledText";
// OTHER UTILS
import workingLocale from "../../../../../../services/locale";
import { moment } from '../../../../../../i18n';
const locale = workingLocale();
const votedImage = require('../../../../../../img/voted.png');
// STYLING
const styles = {
    container: {
        flexDirection: "row",
        alignItems: "center",
        borderBottomWidth: 1,
        borderBottomColor: "#E9E9E9",
        marginHorizontal: 15,
        paddingHorizontal: 10,
        paddingVertical: 25
    },
    titleWrap: {
        flex: 1,
    },
    title: {
        fontSize: 17,
        lineHeight: 21,
        fontWeight: "500",
        color: "#333333",
        flex: 1
    },
    timeLabel: {
        marginTop: 6,
        color: '#333333',
        opacity: 0.6,
        fontSize: 13,
    },
    bullet: {
        width: 24,
        height: 24,
        borderRadius: 12,
        borderWidth: 1,
        borderColor: '#CACACA',
        backgroundColor: '#EEEFF3'
    }
};
export default ({ themeColor, key, index, item, joined, onPress = () => { } }) => {
    return (<TouchableOpacity key={key} onPress={onPress} style={styles.container}>
      <View style={styles.titleWrap}>
        <StyledText style={styles.title}>{item.title}</StyledText>
        <StyledText style={styles.timeLabel}>{`${moment(item.startDate).format('ddd DD, HH:mm')} h — ${moment(item.endDate).format('HH:mm')}`} h</StyledText>
      </View>
      {joined ?
        <Image source={votedImage} style={{ width: 24, height: 24 }}/>
        :
            <View style={styles.bullet}></View>}

    </TouchableOpacity>);
};
