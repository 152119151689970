import React, { useState, useContext } from 'react';
// REACT NATIVE COMPONENTS
import { View, Switch, Alert, TouchableWithoutFeedback } from "react-native";
import ReactMarkdown from 'react-markdown';
// OWN ROOT COMPONENTS
import SmallHeader from '../../components/SmallHeader';
import StyledText from '../../components/StyledText';
import BelongButton from '../../components/BelongButton';
// WRAPPERS AND CONTEXT CREATORS
import { FeathersContext } from "../../services/feathers/FeathersProvider";
// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
import { ScrollView } from 'react-native-gesture-handler';
// OTHER UTILS
import { useTranslation } from 'react-i18next';
import { useLocale } from '../../i18n';
import { useIsBig } from '../../components/BelongResponsive';
// STYLING
const styles = {
    backdrop: {
        backgroundColor: 'rgba(0,0,0,0.7)',
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        overflow: 'hidden'
    },
    container: {
        backgroundColor: "#ffffff",
        flex: 1,
        position: "relative",
    },
    containerBig: {
        position: 'absolute',
        overflow: 'hidden',
        width: '100%',
        maxWidth: 600,
        height: '90vh',
        alignSelf: 'center',
        bottom: -10,
        borderRadius: 10,
        shadowColor: "#000000",
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.08,
        shadowRadius: 4,
    },
    acceptance: {
        marginVertical: 15,
        flexDirection: 'row',
        alignItems: 'center'
    },
    switch: {
        marginRight: 10
    },
    acceptanceText: {
        flex: 1
    },
    button: {
        alignSelf: 'center',
        marginTop: 15
    }
};
const Terms = props => {
    const feathersContext = useContext(FeathersContext);
    const { t, i18n } = useTranslation();
    const localeContext = useLocale();
    const locale = localeContext.workingLocale();
    const [accepted, setAccepted] = useState(false);
    const isBig = useIsBig();
    const submit = () => {
        if (accepted) {
            feathersContext
                .updateUser(props.services.app.feathersUser._id, {
                tcAccepted: true
            })
                .then((r) => {
                //props.navigation.navigate("Home");
            });
        }
        else {
            Alert.alert(t('terms.alertTitle'), t('terms.alertBody'), [
                {
                    text: "Cerrar",
                    onPress: () => console.log("Cancel Pressed"),
                    style: "cancel",
                },
            ], { cancelable: false });
        }
    };
    return (<TouchableWithoutFeedback onPress={() => { }}>
      <View style={styles.backdrop}>
        <View style={{ ...styles.container, ...(isBig ? styles.containerBig : {}) }}>
          <SmallHeader title={t('terms.title')}/>
          <ScrollView style={{}} contentContainerStyle={{ backgroundColor: '#ffffff', paddingHorizontal: 20, paddingVertical: 40 }}>

            
            <View style={{ fontFamily: 'Matter' }}>
              <ReactMarkdown children={props.services.app.manifest.terms[locale].replace(/\n/gi, '  \n')} skipHtml={false}/>
            </View>
            <View style={styles.acceptance}>
              <Switch trackColor={{ false: "#767577", true: props.services.app.manifest.themeColor }} thumbColor={"#f4f3f4"} ios_backgroundColor="#3e3e3e" onValueChange={() => setAccepted(a => !a)} value={accepted} style={styles.switch}/>
              <StyledText tkey="terms.acceptance" style={styles.acceptanceText}>He leido y acepto los términos y condiciones</StyledText>
            </View>
            <BelongButton tkey="terms.submit" onPress={submit} style={{ ...styles.button, backgroundColor: props.services.app.manifest.themeColor }}>Continuar</BelongButton>
          </ScrollView>
        </View>
      </View>
    </TouchableWithoutFeedback>);
};
export default connect((state) => ({
    services: {
        app: state.services.app,
    },
}), null)(Terms);
