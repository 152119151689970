import React, { useContext, useState } from "react";
// REACT NATIVE COMPONENTS
import { View, ScrollView, TouchableWithoutFeedback, Keyboard, } from "react-native";
// OWN ROOT COMPONENTS
import KeyboardWrapper from "../../components/KeyboardWrapper";
import CollapsibleWrapper from "../../components/CollapsibleWrapper";
import CollapsibleHeader from "../../components/CollapsibleHeader";
import BelongIcon from "../../components/BelongIcon";
import StyledText from "../../components/StyledText";
import { Form, FormGroup, Input } from "../../components/Form/index.tsx";
import ScaledImage from "../../components/ScaledImage";
import UploadHandler from "./components/UploadHandler";
// WRAPPERS AND CONTEXT CREATORS
import { FeathersContext } from "../../services/feathers/FeathersProvider";
import { useAlert } from "../../components/BelongAlert";
// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
// OTHER UTILS
import { useForm, Controller } from "react-hook-form";
import validation from "./validation.tsx";
import { useTranslation } from 'react-i18next';
import { useIsBig } from '../../components/BelongResponsive';
const styles = {
    backdrop: {
        backgroundColor: 'rgba(0,0,0,0.7)',
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
    },
    container: {
        backgroundColor: "#EEEFF3",
        flex: 1,
        position: "relative",
    },
    containerBig: {
        width: '100%',
        maxWidth: 400,
        shadowColor: "#000000",
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.08,
        shadowRadius: 4,
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0
    },
    avatarContainer: {
        position: "relative",
    },
    cameraWrap: {
        position: "absolute",
        top: -6,
        right: -6,
        width: 36,
        height: 36,
        borderRadius: 18,
        backgroundColor: "#3B3B3B",
        borderWidth: 2,
        borderColor: "#F1F2F6",
        justifyContent: "center",
        alignItems: "center",
    },
    title: {
        fontSize: 25,
        fontWeight: "500",
        color: "#333333",
        marginTop: 5,
    },
    listItem: {
        height: 70,
        marginHorizontal: 16,
        borderBottomColor: "#E9E9E9",
        borderBottomWidth: 1,
        flexDirection: "row",
        alignItems: "center",
    },
    listItemIcon: {
        color: "#8D8E8F",
        fontSize: 25,
    },
    listItemTitle: {
        color: "#333333",
        fontSize: 17,
        fontWeight: "500",
        marginLeft: 15,
        flex: 1,
    },
    listItemBadge: {
        width: 24,
        height: 24,
        borderRadius: 12,
        backgroundColor: "#D84444",
        justifyContent: "center",
        alignItems: "center",
    },
    listItemBadgeText: {
        fontSize: 13,
        color: "#ffffff",
        fontWeight: "500",
    },
};
const Inbox = (props) => {
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [imageRef, setImageRef] = useState(null);
    const Alert = useAlert();
    // FORM CONTEXT LOAD
    const { control, handleSubmit, errors, reset, setValue } = useForm({
        defaultValues: props.services.app.feathersUser,
    });
    // FEATHERS CONTEXT LOAD
    const feathersContext = useContext(FeathersContext);
    const { t, i18n } = useTranslation();
    const addhttp = (url) => {
        if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
            url = "https://" + url;
        }
        return url;
    };
    const onSubmit = (data) => {
        //alert(JSON.stringify(data));
        const toSend = data;
        if (toSend.password == '')
            delete toSend.password;
        if (toSend.social?.linkedin && toSend.social?.linkedin != '') {
            toSend.social.linkedin = addhttp(toSend.social.linkedin);
            setValue('social.linkedin', addhttp(toSend.social.linkedin));
        }
        if (toSend.social?.instagram && toSend.social?.instagram != '') {
            toSend.social.instagram = addhttp(toSend.social.instagram);
            setValue('social.instagram', addhttp(toSend.social.instagram));
        }
        if (toSend.social?.facebook && toSend.social?.facebook != '') {
            toSend.social.facebook = addhttp(toSend.social.facebook);
            setValue('social.facebook', addhttp(toSend.social.facebook));
        }
        if (toSend.social?.other && toSend.social?.other != '') {
            toSend.social.other = addhttp(toSend.social.other);
            setValue('social.other', addhttp(toSend.social.other));
        }
        feathersContext
            .updateUser(props.services.app.feathersUser._id, {
            ...toSend,
        })
            .then((r) => {
            setImageRef(null);
            Alert.alert(t('profile.successTitle'), t('profile.successBody'), [
                {
                    text: t('profile.successOk'),
                    onPress: () => console.log("Cancel Pressed"),
                    style: "cancel",
                },
            ], { cancelable: false });
        })
            .catch((e) => {
            alert(e.message);
            console.log("Feathers error", e);
        });
    };
    const isBig = useIsBig();
    return (<KeyboardWrapper style={{ flex: 1, position: "relative" }}>
      <TouchableWithoutFeedback onPress={() => props.navigation.goBack()}>
        <View style={styles.backdrop}>
          <TouchableWithoutFeedback onPress={e => e.stopPropagation()}>
            <View style={{ ...styles.container, ...(isBig ? styles.containerBig : {}) }}>
              <CollapsibleWrapper isSidebar headerComponent={(disappearingOpacity, appearingOpacity) => (<CollapsibleHeader central={<>
                        <UploadHandler style={styles.avatarContainer} setLoading={setLoading} setProgress={setProgress} setImageRef={setImageRef} setValue={setValue} app={props.services.app}>
                          {props.services.app.feathersUser.picture || imageRef ? (<ScaledImage width={240} height={240} fit="cover" image={imageRef || props.services.app.feathersUser.picture} style={{
        width: 80,
        height: 80,
        borderRadius: 40,
        backgroundColor: "#333",
    }}/>) : (<View style={{
        width: 80,
        height: 80,
        borderRadius: 40,
        backgroundColor: "#333",
    }}></View>)}
                          <View style={styles.cameraWrap}>
                            <BelongIcon name="md-camera" color="#ffffff"/>
                          </View>
                        </UploadHandler>
                        <StyledText tkey="profile.title" style={styles.title}>Área personal</StyledText>
                      </>} backAction={() => props.navigation.goBack()} mainAction={handleSubmit(onSubmit)} mainActionTitle={t('profile.save')} disappearingOpacity={disappearingOpacity} appearingOpacity={appearingOpacity} themeColor={props.services.app.manifest.themeColor}/>)} backgroundColor="#EEEFF3" headerComponentStyle={{
        backgroundColor: "#EEEFF3",
        paddingHorizontal: 15,
    }} bodyComponentStyle={{ flex: 1 }} scrollViewOverride={(onScroll, scrollEventThrottle, contentContainerPadding) => (<ScrollView style={{ flex: 1 }} contentContainerStyle={{
        paddingTop: contentContainerPadding,
        paddingHorizontal: 15,
    }} onScroll={onScroll} scrollEventThrottle={scrollEventThrottle}>
                    <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
                      <Form {...{ control, validation, errors }}>
                        <FormGroup>
                          <Controller control={control} name="picture" defaultValue=""/>
                          <Input name="firstName" label={t('profile.labels.firstName')}/>
                          <Input name="lastName" label={t('profile.labels.lastName')}/>
                          <Input name="subtitle" label={t('profile.labels.subtitle')} last/>
                        </FormGroup>
                        <FormGroup>
                          <Input name="email" label={t('profile.labels.email')}/>
                          <Input name="phone" label={t('profile.labels.phone')} last/>
                        </FormGroup>
                        <FormGroup>
                          <Input autoComplete="bday" name="social.birthday" label={t('profile.labels.birthday')} autoCapitalize={false} last/>
                        </FormGroup>
                        <FormGroup>
                          <Input autoComplete="new-password" secureTextEntry name="password" label={t('profile.labels.newPassword')} last/>
                        </FormGroup>
                        <FormGroup>
                          <Input name="social.linkedin" label="https://www.linkedin.com/in/myprofile/" autoCapitalize={false}/>
                          <Input name="social.instagram" label="https://www.instagram.com/myprofile/" autoCapitalize={false}/>
                          <Input name="social.facebook" label="https://www.facebook.com/myprofile/" autoCapitalize={false}/>
                          <Input name="social.other" label={t('profile.labels.othersocial')} autoCapitalize={false} last/>
                        </FormGroup>

                        <FormGroup>
                          <View />
                          <Input name="bio" label={t('profile.labels.bio')} multiline numberOfLines={15} containerStyle={{
        height: 300,
    }} last/>
                        </FormGroup>
                      </Form>
                    </TouchableWithoutFeedback>
                  </ScrollView>)}/>
            </View>
          </TouchableWithoutFeedback>
        </View>
      </TouchableWithoutFeedback>
    </KeyboardWrapper>);
};
export default connect((state) => ({
    services: {
        app: state.services.app,
        session: state.services.session,
    },
}), null)(Inbox);
