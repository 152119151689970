import React, { useState, useEffect, useContext } from 'react';
// REACT NATIVE COMPONENTS
import { View, ScrollView, Platform, TouchableWithoutFeedback, } from "react-native";
// OWN ROOT COMPONENTS
import SmallHeader from '../../../../components/SmallHeader';
import StyledText from '../../../../components/StyledText';
// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
import { FeathersContext } from "../../../../services/feathers/FeathersProvider";
// OTHER UTILS
import moment from "moment-timezone";
import workingLocale from "../../../../services/locale";
import BelongButton from '../../../../components/BelongButton';
import { useTranslation } from 'react-i18next';
import { useIsBig } from '../../../../components/BelongResponsive';
const locale = workingLocale();
// STYLING
const styles = {
    backdrop: {
        backgroundColor: 'rgba(0,0,0,0.7)',
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        overflow: 'hidden'
    },
    container: {
        flex: 1
    },
    containerBig: {
        position: 'absolute',
        overflow: 'hidden',
        width: '100%',
        maxWidth: 600,
        minHeight: 600,
        maxHeight: '60vh',
        alignSelf: 'center',
        bottom: -10,
        borderRadius: 10,
        shadowColor: "#000000",
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.08,
        shadowRadius: 4,
    },
    scrollView: {
        ...(Platform.OS == 'web' ? { flex: 1 } : { flexGrow: 1 }),
    },
    headerInfo: {
        flexDirection: 'column',
        paddingHorizontal: 20
    },
    title: {
        fontSize: 25,
        fontWeight: "500",
        color: "#FFFFFF",
        marginTop: 10,
    },
    subtitle: {
        fontSize: 17,
        lineHeight: 19,
        fontWeight: 'normal',
        color: "#FFFFFF",
        opacity: 0.6,
        marginTop: 5,
        marginBottom: 30
    },
    detail: {
        backgroundColor: '#ffffff',
        flex: 1,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        padding: 15,
        paddingHorizontal: 0,
    },
    detailTitle: {
        fontSize: 20,
        lineHeight: 26,
        fontWeight: '500',
        color: "#333333",
        marginTop: 10,
        marginBottom: 10,
        paddingHorizontal: 20
    },
    detailBody: {
        fontSize: 16,
        lineHeight: 24,
        color: "#444444",
        flex: 1,
        backgroundColor: '#ffffff',
        paddingHorizontal: 20
    }
};
const jsUcfirst = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};
const Component = props => {
    const [item, setItem] = useState(false);
    const [overlaps, setOverlaps] = useState([]);
    const { t, i18n } = useTranslation();
    const isBig = useIsBig();
    // FEATHERS CONTEXT LOAD
    const feathersContext = useContext(FeathersContext);
    useEffect(() => {
        setItem(props.route.params.item);
        feathersContext.onlyFind('appointments', {
            query: {
                overlapFrom: props.route.params.item.startDate,
                overlapTo: props.route.params.item.endDate,
                onlyMandatory: true
            }
        }).then(r => {
            setOverlaps(r.data);
        });
    }, [props.route.params.item]);
    const toggleJoin = () => {
        if (item.attendees.includes(props.services.app.feathersUser._id)) {
            feathersContext.patch("appointments", props.route.params.item._id, {
                appId: props.services.app.slug,
                leave: true
            }).then(r => {
                setItem(r);
                props.route.params.setItem(r);
            });
        }
        else {
            if (item.attendees.length >= item.seats) {
                alert(t('agenda.full'));
            }
            else {
                if (overlaps.length > 0) {
                    alert(`${t('agenda.overlap')}: ${overlaps.map(ol => ol.title.en).join(', ')}`);
                }
                else {
                    feathersContext.patch("appointments", props.route.params.item._id, {
                        appId: props.services.app.slug,
                        join: true
                    }).then(r => {
                        if (r.error && r.error == 'full')
                            alert("The session is full");
                        else {
                            setItem(r);
                            props.route.params.setItem(r);
                        }
                    });
                }
            }
        }
    };
    return (<TouchableWithoutFeedback onPress={() => props.navigation.goBack()}>
      <View style={styles.backdrop}>
        <TouchableWithoutFeedback onPress={e => e.stopPropagation()}>
          <View style={{ ...styles.container, backgroundColor: props.services.app.manifest.themeColor, ...(isBig ? styles.containerBig : {}) }}>
            <SmallHeader negative title={props.route.params.title} noRound numberOfLines={1} backAction={() => props.navigation.goBack()}/>
            <ScrollView contentContainerStyle={styles.scrollView} style={{ flex: 1 }}>
              <View style={styles.headerInfo}>
                <StyledText variant="h1" style={styles.title}>
                  {item.title}
                </StyledText>
                <StyledText variant="h1" style={styles.subtitle}>
                  {moment(item.startDate).isSame(moment(item.endDate), 'day') ?
        `${jsUcfirst(moment(item.startDate).format('dddd D, HH:mm'))} h — ${moment(item.endDate).format('HH:mm')} h`
        :
            `${jsUcfirst(moment(item.startDate).format('dddd D, HH:mm'))} h — ${jsUcfirst(moment(item.endDate).format('dddd D, HH:mm'))} h`}
                </StyledText>
              </View>
              <View style={styles.detail}>
                {props.services.app.feathersUser && props.route.params.canJoin && item.optional ?
        <BelongButton onPress={toggleJoin} tkey={item.attendees.includes(props.services.app.feathersUser._id) ? 'agenda.leave' : 'agenda.join'} style={{ alignSelf: 'center' }}></BelongButton>
        : null}
                <StyledText variant="h2" style={styles.detailTitle} tkey="agenda.info">
                  Info
                </StyledText>
                <StyledText style={styles.detailBody}>
                  {item.body}
                </StyledText>
                <StyledText style={styles.detailBody}>
                  {'\n\n\n'}
                </StyledText>

              </View>
            </ScrollView>

          </View>
        </TouchableWithoutFeedback>
      </View>
    </TouchableWithoutFeedback>);
};
export default connect((state) => ({
    services: {
        app: state.services.app,
    },
}), null)(Component);
